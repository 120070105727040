import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import style from './style'

const Toggle = ({
    classes,
    active = false,
    toggleActive,
}) => {
    return (
        <div onClick={typeof toggleActive === 'function' && toggleActive} className={ cx(classes.toggle, { active }) } />
    )
}

export default is(style)(Toggle)
