export default ({
    spaces,
    colors,
    helpers,
}) => ({
    key: { name: 'PlayerVolumeSlider' },
    
    track: {
        width: '100%',
        height: 3,
        backgroundColor: helpers.hexToRgba(colors.white, .25),
    },

    trackInner: {
        height: 3,
        backgroundColor: colors.white,
    },

    thumb: {
        height: 10,
        width: 10,
        borderRadius: 10,
        backgroundColor: colors.white,
    },
})