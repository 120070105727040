import React, { useState, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import * as _ from 'underscore'
import is from 'react-jss'
import cx from 'classnames'

import { useVideoState } from '../../context'

import Controls from './components/Controls'

import style from './style'

const Player = ({
	classes,
}) => {
	const { activeVideo, disableVideoPlayer } = useVideoState()

	const video = activeVideo?.video ?? false
	const poster = activeVideo?.poster ?? false

	const [ showControls, setShowControls ] = useState(true)
	const [ wrapper, setWrapper ] = useState(false)
	const [ player, setPlayer ] = useState(false)
	const [ url, setUrl ] = useState(video)
	const [ ready, setReady ] = useState(false)
	const [ pip, setPip ] = useState(false)
	const [ playing, setPlaying ] = useState(true)
	const [ ended, setEnded ] = useState(false)
	const [ controls, setControls ] = useState(false)
	const [ light, setLight ] = useState(false)
	const [ volume, setVolume ] = useState(0.5)
	const [ muted, setMuted ] = useState(false)
	const [ played, setPlayed ] = useState(0)
	const [ loaded, setLoaded ] = useState(0)
	const [ seeking, setSeeking ] = useState(false)
	const [ duration, setDuration ] = useState(0)
	const [ playbackRate, setPlaybackRate ] = useState(1.0)
	const [ loop, setLoop ] = useState(false)
	const social = [
		'www.youtube.com',
		'www.facebook.com',
		'www.vimeo.com',
		'www.twitch.com',
		'soundcloud',
		'streamable',
		'mixcloud',
		'dailymotion',
		'vidyard',
	]

	const handleReady = () => {
		setReady(true)
	}

	const handleClose = () => {
		setUrl(null)
		setPlaying(false)
		setReady(false)
		disableVideoPlayer()
	}

	const handlePlay = () => {
		setPlaying(true)
		setEnded(false)
	}

	const handlePause = () => {
		setPlaying(false)
	}

	const handleProgress = ({ loaded, played }) => {
		if (!seeking) {
			setLoaded(loaded)
			setPlayed(played)
		}
	}

	const handleEnded = () => {
		setPlaying(loop)

		if (!loop) {
			setEnded(true)
		}
	}

	const handleDuration = (duration) => {
		setDuration(duration)
	}

	const innerRef = (wrapperRef) => {
		setWrapper(wrapperRef)
	}

	const ref = (playerRef) => {
		setPlayer(playerRef)
	}

	useEffect(() => {
		if (video) {
			setUrl(video)
			setPlaying(true)
			setEnded(false)
			setReady(true)

			if (new RegExp(social.join('|')).test(video)) setControls(true)
			setControls(false)

			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'unset'
		}
	}, [video])

	return url ? (
		<div className={ cx(classes.container, { [classes.ready]: ready }) }>
			<div ref={innerRef} className={classes.inner}>
				<ReactPlayer
					ref={ref}
					className="react-player"
					width="100%"
					height="100%"
					url={url}
					pip={pip}
					playing={playing}
					controls={controls}
					light={light}
					loop={loop}
					playbackRate={playbackRate}
					volume={volume}
					muted={muted}
					onReady={ () => handleReady() }
					// onStart={ () => console.log('onStart') }
					onPlay={ (e) => handlePlay(e) }
					onEnablePIP={ () => setPip(true) }
					onDisablePIP={ () => setPip(false) }
					onPause={ (e) => handlePause(e) }
					// onBuffer={ () => console.log('onBuffer') }
					// onSeek={ (e) => console.log('onSeek', e) }
					onEnded={ (e) => handleEnded(e) }
					// onError={e => console.log('onError', e) }
					onProgress={ (e) => handleProgress(e) }
					onDuration={ (e) => handleDuration(e) }
					playsinline
				/>
				{ !controls && (
					<Controls { ...{
						showControls,
						player,
						wrapper,
						url, setUrl,
						pip, setPip,
						playing, setPlaying,
						ended, setEnded,
						light, setLight,
						volume, setVolume,
						muted, setMuted,
						played, setPlayed,
						duration, setDuration,
						loaded,
						seeking, setSeeking,
						loop, setLoop,
						controls, setControls,
					} } />
				) }
				<div className={cx(classes.close, 'tp-icon-video-close')} onClick={ (e) => handleClose() } />
			</div>
		</div>
	) : null
} 

export default is(style)(Player)

