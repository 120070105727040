const fontstacks = {
  primary: `'Zona Pro', 'Helvetica Nueue', 'Arial', sans-serif`,
}

const colors = {
  primary: "#01c4fe",
  secondary: "#f140ff",
  tertiary: "#000034",

  white: "#ffffff",
  black: "#000000",
  grey: "#59585d",

  pink: "#f33fff",
  blue: "#6653fd",
  red: "#ed0501",
  yellow: "#ffc813",
  orange: "#f65600",

  darkGrey: "#5a595e",
  darkYellow: "#ff9629",

  lightTertiary: "#614ff2",
  lightGrey: "#e0e0e0",
}

const fontSizes = {
  xs: "0.75rem",
  sm: "0.875rem",
  base: "1rem",
  lg: "1.125rem",
  xl: "1.25rem",
  xl2: "1.5rem",
  xl3: "1.875rem",
  xl4: "2.25rem",
  xl5: "3rem",
  xl6: "4rem",
}

const spaces = [
  8, // 0
  16, // 1
  24, // 2
  32, // 3
  64, // 4
  100, // 5
  200, // 6
]

const breakpoints = {
  xs: "@media only screen and (min-width: 320px)",
  sm: "@media only screen and (min-width: 640px)",
  md: "@media only screen and (min-width: 768px)",
  lg: "@media only screen and (min-width: 1024px)",
  xlg: "@media only screen and (min-width: 1280px)",
  max: "@media only screen and (min-width: 1400px)",

  mw: {
    xs60: "@media only screen and (max-width: 370px)",
    xs: "@media only screen and (max-width: 319px)",
    s: "@media only screen and (max-width: 574px)",
    sm: "@media only screen and (max-width: 639px)",
    md: "@media only screen and (max-width: 767px)",
    tb: "@media only screen and (max-width: 991px)",
    lg: "@media only screen and (max-width: 1022px)",
    xlg: "@media only screen and (max-width: 1279px)",
    max: "@media only screen and (max-width: 1399px)",
  },
}

export default {
  fontstacks,
  colors,
  spaces,
  fontSizes,
  breakpoints,
}
