export default ({
    spaces,
    typography,
    colors,
    breakpoints,
    gradients,
}) => ({
    settings: {
        display: 'block',
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: colors.blue,
        color: colors.white,
        zIndex: 52,
        overflowY: 'scroll',

        [breakpoints.xs]: {
            maxWidth: 400,
        },

        [breakpoints.md]: {
            padding: spaces[1],
        },

        '&::before': {
            display: 'block',
            width: '200%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            background: gradients.radial.top.secondary,
            content: '""',
            pointerEvents: 'none',
        },
    },

    inner: {
        padding: spaces[1],
        position: 'relative',
        zIndex: 52,
    },

    heading: {
        ...typography.p,
        margin: `0 0 ${spaces[1]}px!important`,
        fontWeight: 700,
        color: colors.white,
    },

    body: {
        '& > p': {
            ...typography.small,
            color: colors.white,
        },
    },

    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        margin: [spaces[2], 0, 0],
    },

    button: {
        '&.save': {
            margin: [spaces[2], 0, 0],
        },
    },

    type: {
        padding: [spaces[2], 0, 0],
        margin: [spaces[2], 0, 0],
        borderTop: `1px solid ${colors.white}`,

        '&:first-child': {
            borderTop: 0,
        },
    },

    typeHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: [0, 0, spaces[1]],
    },

    typeHeading: {
        ...typography.small,
        fontWeight: 700,
        margin: 0,
        color: colors.white,
    },

    typeDetails: {
        ...typography.small,
        margin: 0,
        color: colors.white,
    },

    privacy: {
        ...typography.small,
        padding: [spaces[2], 0, 0],
        margin: [spaces[2], 0, 0],
        borderTop: `1px solid ${colors.white}`,
    },

    privacyLink: {
        fontWeight: 900,
    },
})
