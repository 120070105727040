import React, { useState } from 'react'
import { Range } from 'react-range'
import is from 'react-jss'

import style from './style'

const Seeker = ({
    classes,
    player,
    setPlaying,
    setSeeking,
    played, setPlayed,
}) => {
    const [ ref, setRef ] = useState(false)

    const setSeekerRef = (seekerRef) => {
        setRef(seekerRef)
    }

	const handleSeekChange = (values) => {
        setSeeking(true)
        setPlaying(false)
        setPlayed(parseFloat(values[0]))
        player.seekTo(parseFloat(values[0]))
    }

	const handleSeekMouseUp = () => {
        setPlaying(true)
        setSeeking(false)
	}

    return (
        <Range
            ref={setSeekerRef}
            step={0.025}
            min={0}
            max={1}
            values={[ played ]}
            onChange={ (values) => handleSeekChange(values) }
            renderTrack={ ({ props, children }) => (
                <div { ...props } onMouseUp={handleSeekMouseUp} className={classes.track}>
                    { children }
                    <span className={classes.trackInner} />
                    <span className={classes.trackerProgress} style={{ width: `${played * 100}%` }} />
                </div>
            ) }
            renderThumb={ ({ props }) => (
                <div { ...props } className={classes.thumb} />
            ) }
            onMouseDown={() => alert('test')}
        />
    )
}

export default is(style)(Seeker)
