import variables from './variables'

const {
    colors,
} = variables

const hexToRgba = (hex, opacity) => {
    let c;
    
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length===3) {
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }

        c = '0x' + c.join('');
        return 'rgba(' + [(c>>16)&255, (c>>8)&255, c&255].join(',') + ',' + opacity + ')';
    }
    throw new Error('Bad Hex');
}

const clipCorners = {
    default: {
        '-webkit-clip-path': 'polygon(0% 0%, 90% 0%, 100% 30%, 100% 100%, 100% 100%, 10% 100%, 0% 70%, 0% 20%)',
        'clip-path': 'polygon(0% 0%, 90% 0%, 100% 30%, 100% 100%, 100% 100%, 10% 100%, 0% 70%, 0% 20%)',
    },

    animated: {
        '-webkit-clip-path': 'polygon(10% 0%, 100% 0%, 100% 0%, 100% 70%, 90% 100%, 0% 100%, 0% 100%, 0% 30%)',
        'clip-path': 'polygon(10% 0%, 100% 0%, 100% 0%, 100% 70%, 90% 100%, 0% 100%, 0% 100%, 0% 30%)',
    },
}

const clipSides = {
    left: {
        default: {
            '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 100% 100%, 45% 100%, 0% 0%, 0% 0%)',
            'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 100% 100%, 45% 100%, 0% 0%, 0% 0%)',
        },
    
        animated: {
            '-webkit-clip-path': 'polygon(45% 0%, 100% 0%, 100% 100%, 0% 100%, 100% 100%, 0% 100%, 45% 0%, 45% 0%)',
            'clip-path': 'polygon(45% 0%, 100% 0%, 100% 100%, 0% 100%, 100% 100%, 0% 100%, 45% 0%, 45% 0%)',
        },
    },

    right: {
        default: {
            '-webkit-clip-path': 'polygon(0% 0%, 65% 0%, 100% 100%, 0% 100%)',
            'clip-path': 'polygon(0% 0%, 65% 0%, 100% 100%, 0% 100%)',
        },
    
        animated: {
            '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 65% 100%, 0% 100%)',
            'clip-path': 'polygon(0% 0%, 100% 0%, 65% 100%, 0% 100%)',
        },
	},
	
	both: {
		default: {
            '-webkit-clip-path': 'polygon(0% 0%, 75% 0%, 100% 100%, 25% 100%)',
            'clip-path': 'polygon(0% 0%, 75% 0%, 100% 100%, 25% 100%)',
        },
    
        animated: {
            '-webkit-clip-path': 'polygon(0% 0%, 75% 0%, 100% 100%, 25% 100%)',
            'clip-path': 'polygon(0% 0%, 75% 0%, 100% 100%, 25% 100%)',
        },
	}
}

const clipRect = (
    topLeft,
    topRight,
    bottomRight,
    bottomLeft,
) => ({
    '-webkit-clip-path': `polygon(${topLeft}, ${topRight}, ${bottomRight}, ${bottomLeft})`,
            'clip-path': `polygon(${topLeft}, ${topRight}, ${bottomRight}, ${bottomLeft})`,
})

const gradientText = {
    background: colors.primary,
    background: `-moz-linear-gradient(45deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
    background: `-webkit-linear-gradient(45deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
    background: `linear-gradient(45deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
    filter: `progid:DXImageTransform.Microsoft.gradient(startColorstr="${colors.primary}", endColorstr="${colors.secondary}", GradientType=1)`,
    color: 'transparent',
    '-webkit-background-clip': 'text',
    backgroundClip: 'text',

    '.browser.ie &': {
        background: 'none',
        filter: 'none',
        color: colors.primary,
        '-webkit-background-clip': 'none',
        backgroundClip: 'none',
    },
}

const gradientBackgrounds = {
	topBottom: {
		background: colors.primary,
		background: `-moz-linear-gradient(0deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
		background: `-webkit-linear-gradient(0deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
		background: `linear-gradient(0deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
	},
	bottomTop: {
		background: colors.primary,
		background: `-moz-linear-gradient(180deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
		background: `-webkit-linear-gradient(180deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
		background: `linear-gradient(180deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
	},
	leftRight: {
		background: colors.primary,
		background: `-moz-linear-gradient(90deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
		background: `-webkit-linear-gradient(90deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
		background: `linear-gradient(90deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
	},
	rightLeft: {
		background: colors.primary,
		background: `-moz-linear-gradient(270deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
		background: `-webkit-linear-gradient(270deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
		background: `linear-gradient(270deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
	},

	tertiarySecondary: {
		background: 'transparent',
		background: `-moz-linear-gradient(180deg, ${colors.tertiary} -20%, ${colors.secondary} 150%)`,
		background: `-webkit-linear-gradient(180deg, ${colors.tertiary} -20%, ${colors.secondary} 150%)`,
		background: `linear-gradient(180deg, ${colors.tertiary} -20%, ${colors.secondary} 150%)`,
    },

    fadeOut: {
        bottomTop: {
            background: 'transparent',
            background: `-moz-linear-gradient(180deg, ${hexToRgba(colors.primary, 0)} 0%, ${hexToRgba(colors.primary, .75)} 50%, ${hexToRgba(colors.secondary, 1)} 100%)`,
            background: `-webkit-linear-gradient(180deg, ${hexToRgba(colors.primary, 0)} 0%, ${hexToRgba(colors.primary, .75)} 50%, ${hexToRgba(colors.secondary, 1)} 100%)`,
            background: `linear-gradient(180deg, ${hexToRgba(colors.primary, 0)} 0%, ${hexToRgba(colors.primary, .75)} 50%, ${hexToRgba(colors.secondary, 1)} 100%)`,
        },
    },
}

export default {
	clipCorners,
    clipRect,
    clipSides,
	gradientBackgrounds,
    gradientText,
    hexToRgba,
}
