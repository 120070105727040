import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import style from './style'

const Button = ({
    classes,
    className,
    clickHandler,
	color = 'white',
	disabled = false,
    children,
}) => (
    <div
        className={ cx(
            classes.button,
            className,
			classes[`color_${color}`] || classes.color_white,
			{
				[classes.disabled]: disabled,
			},
        ) }
        onClick={ e => typeof clickHandler === 'function' && clickHandler(e) }
    >
        <span className={classes.inner}>
            <span className={classes.children}>{ children }</span>
        </span>
    </div>
)

export default is(style)(Button)
