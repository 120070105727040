export default ({}) => ({
    key: { name: 'RandomText' },

    full: {
        opacity: 0,
	},
	
    alwaysShow: {
        opacity: 1,
    },

    animated: {
        opacity: 1,
    },
})
