export default ({
    spaces,
    colors,
    breakpoints,
}) => ({
    key: { name: 'Button' },
    
    button: {
        display: 'inline-block',
        padding: [0, 18], // account for the scew background
        userSelect: 'none',

        '&:not(.no-hover)': {
            cursor: 'pointer',
        },
    },

    inner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 62,
        transform: 'skewX(30deg)',
        transition: 'all ease-in-out .15s',
    },

    secondInner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 80,
        transform: 'skewX(30deg)',
        transition: 'all ease-in-out .15s',
    },

    children: {
        display: 'inline-block',
        position: 'relative',
        transform: 'skewX(-30deg)',
        padding: [0, spaces[2]],
        margin: 0,
        fontSize: 14,
        textTransform: 'uppercase',
        fontWeight: 700,
        transition: 'all ease-in-out .15s',
        zIndex: 2,

        [breakpoints.sm]: {
            fontSize: 16,
        },

        [breakpoints.md]: {
            padding: [0, spaces[3]],
        },

        '.thin &': {
            padding: [0, spaces[1]],
        },
    },

    color_white: {
        '& $inner': {
            backgroundColor: colors.white,
            color: colors.black,
        },
        '& $secondInner': {
            backgroundColor: colors.white,
            color: colors.black,
        },

        '&:not(.no-hover)': {
            cursor: 'pointer',

            '&:hover, .hovered &': {
                '& $inner': {
                    backgroundColor: colors.black,

                    '& $children': {
                        color: colors.white,
                    },
                },

                '& svg': {
                    color: colors.white,
                    fill: colors.white,
                },
            },
        },
    },

    color_black: {
        '& $inner': {
            backgroundColor: colors.black,
            color: colors.white,
        },
        '& $secondInner': {
            backgroundColor: colors.black,
            color: colors.white,
        },

        '&:not(.no-hover), .hovered &': {
            cursor: 'pointer',

            '&:hover': {
                '& $inner': {
                    backgroundColor: colors.white,

                    '& $children': {
                        color: colors.black,
                    },
                },

                '& svg': {
                    color: colors.black,
                    fill: colors.black,
                },
            },
        },
    },

    color_gradient: {
        '& $inner': {
            background: `linear-gradient(left, ${colors.primary}, ${colors.secondary})`,
        },

        '&:not(.no-hover)': {
            cursor: 'pointer',

            '&:hover, .hovered &': {
                '& $inner': {
                    '& $children': {
                        background: colors.primary,
                        background: `-moz-linear-gradient(45deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
                        background: `-webkit-linear-gradient(45deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
                        background: `linear-gradient(45deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
                        filter: `progid:DXImageTransform.Microsoft.gradient(startColorstr="${colors.primary}", endColorstr="${colors.secondary}", GradientType=1)`,
                        color: 'transparent',
                        '-webkit-background-clip': 'text',
                        backgroundClip: 'text',
                    },
                }
            },
        },
	},
	
	disabled: {
		opacity: 0,
		pointerEvents: 'none',
	}
})