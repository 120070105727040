export default ({
    colors,
}) => ({
    toggle: {
        width: 42,
        height: 24,
        backgroundColor: colors.grey,
        position: 'relative',
        borderRadius: 24,
        cursor: 'pointer',
        transition: 'background-color ease-in-out .15s',

        '&::before': {
            display: 'block',
            width: 18,
            height: 18,
            borderRadius: 18,
            backgroundColor: colors.black,
            position: 'absolute',
            top: 3,
            left: 3,
            content: '""',
            transition: 'all ease-in-out .15s',
        },

        '&.active': {
            backgroundColor: colors.white,

            '&::before': {
                transform: 'translate3d(100%, 0, 0)',
                backgroundColor: colors.secondary,
            },
        },
    },
})
