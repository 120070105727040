import React, { useState } from 'react'
import is from 'react-jss'
import cx from 'classnames'

import Link from '../../../Link'
import RandomText from '../../../RandomText'

import style from './style'

const Slide = ({
	classes,
	images = [],
	label,
	slug,
	handleClick,
}) => {
    const [ hover, setHover ] = useState(false)

    return (
		<div className={cx(classes.slideParent, {
			[classes.hover]: hover
		})}>
			<Link
				to={slug}
				className={classes.slide}
				onMouseEnter={ e => setHover(true) }
				onMouseLeave={ e => setHover(false) }
				clickHandler={ e => handleClick() }
			>
				<div className={classes.image}>
					{ images.map((src, i) => (
						<div className={cx(classes.bgimage)} key={i} style={{ backgroundImage: `url(${src})`}}></div>
					))}
				</div>

				<span className={cx(classes.label)}>
					<RandomText {...{
						speed: 25,
						full: true,
						animate: hover,
						alwaysShow: true,
					}} >{ label }</RandomText>
				</span>
			</Link>
		</div>
	)
}

export default is(style)(Slide)