export default ({
    spaces,
    colors,
    helpers,
}) => ({
    key: { name: 'PlayerControls' },
    
    overlay: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 20,
    },

    controls: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        position: 'absolute',
        right: 0,
        bottom: 0,
        color: `${colors.white}!important`,
        background: `-moz-linear-gradient(180deg, transparent 0%, ${helpers.hexToRgba(colors.secondary, .35)} 100%)!important`,
        background: `-webkit-linear-gradient(180deg, transparent 0%, ${helpers.hexToRgba(colors.secondary, .35)} 100%)!important`,
        background: `linear-gradient(180deg, transparent 0%, ${helpers.hexToRgba(colors.secondary, .35)} 100%)!important`,
        zIndex: 20,
        opacity: 0,
        transition: 'opacity ease-in-out .25s',
        pointerEvents: 'none',

        '&.show': {
            opacity: 1,
            pointerEvents: 'auto',
        },
    },

    squareButton: {
        height: 60,
        maxHeight: 60,
        width: 60,
        maxWidth: 60,
        cursor: 'pointer',
    },

    volume: {
        display: 'inline-flex',
        alignItems: 'center',
        minWidth: 100,
    },

    progress: {
        margin: [0, spaces[1]],
        fontSize: 12,
        fontWeight: 700,
    },

    seek: {
        flex: 1,
    },

    seekBar: {
        width: '100%',
    },

    icon: {
		fontFamily: 'Tilting Point !important',
		speak: 'none',
		fontSize: 24,
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontVariant: 'normal',
        textTransform: 'none',
		lineHeight: 1,
        color: colors.white,
		'-webkit-font-smoothing': 'antialiased',
		'-moz-osx-font-smoothing': 'grayscale',

		'&.restart': {
			'&::before': {
				content: '"\\e905"!important',
			},
		},

		'&.pause': {
			'&::before': {
				content: '"\\e905"!important',
			},
		},

		'&.play': {
			'&::before': {
				content: '"\\e902"!important',
			},
		},

		'&.mute': {
			'&::before': {
				content: '"\\e904"!important',
			},
		},

		'&.unmute': {
			'&::before': {
				content: '"\\e904"!important',
			},
		},

		'&.fullscreen': {
			'&::before': {
				content: '"\\e901"!important',
			},
		},
	},
})