import { config, library } from '@fortawesome/fontawesome-svg-core'

import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn'
import { faPlaystation } from '@fortawesome/free-brands-svg-icons/faPlaystation'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faXbox } from '@fortawesome/free-brands-svg-icons/faXbox'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'

import { faAngleUp } from '@fortawesome/fontawesome-pro-regular/faAngleUp'
import { faAngleRight } from '@fortawesome/fontawesome-pro-regular/faAngleRight'
import { faAngleDown } from '@fortawesome/fontawesome-pro-regular/faAngleDown'
import { faAngleLeft } from '@fortawesome/fontawesome-pro-regular/faAngleLeft'
import { faCheckCircle } from '@fortawesome/fontawesome-pro-regular/faCheckCircle'
import { faPlayCircle } from '@fortawesome/fontawesome-pro-regular/faPlayCircle'
import { faTimes } from '@fortawesome/fontawesome-pro-regular/faTimes'

import { faTimesCircle } from '@fortawesome/fontawesome-pro-solid/faTimesCircle'
import { faStar } from '@fortawesome/fontawesome-pro-solid/faStar'

import { faStarHalf } from '@fortawesome/fontawesome-pro-solid/faStarHalf'

import { faMobile } from '@fortawesome/fontawesome-pro-light/faMobile'

export default () => {
	config.autoAddCss = false

	library.add(
		faFacebookSquare,
		faInstagram,
		faLinkedin,
		faLinkedinIn,
		faPlaystation,
		faTwitter,
		faXbox,
		faYoutube,
		faAngleUp,
		faAngleRight,
		faAngleDown,
		faAngleLeft,
		faCheckCircle,
		faPlayCircle,
		faTimes,
		faTimesCircle,
		faStar,
		faMobile,
		faStarHalf,
	)
}
