export default {
    '.video-react .video-react-control::before': {
        fontSize: '1.8em',
        lineHeight: '1.67'
    },

    '.video-react .video-react-big-play-button::before': {
        content: '""',
    },

    '.video-react-icon': {
        fontFamily: '\'.video-react\' !important',
        speak: 'none',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: '1',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale'
    },

    '.video-react .video-react-closed-caption': {
        cursor: 'pointer',
        WebkitBoxFlex: 'none',
        MozBoxFlex: 'none',
        WebkitFlex: 'none',
        MsFlex: 'none',
        flex: 'none'
    },

    '.video-react .video-react-bezel .video-react-bezel-icon': {
        width: '36px',
        height: '36px',
        margin: '8px',
        fontSize: '26px',
        lineHeight: '36px',
        textAlign: 'center'
    },

    '.video-react .video-react-volume-level': {
        position: 'absolute',
        bottom: '0',
        left: '0',
        backgroundColor: '#fff'
    },

    '.video-react .video-react-mute-control': {
        cursor: 'pointer',
        WebkitBoxFlex: 'none',
        MozBoxFlex: 'none',
        WebkitFlex: 'none',
        MsFlex: 'none',
        flex: 'none'
    },

    '.video-react .video-react-volume-menu-button': {
        cursor: 'pointer',
        WebkitBoxFlex: 'none',
        MozBoxFlex: 'none',
        WebkitFlex: 'none',
        MsFlex: 'none',
        flex: 'none'
    },

    '.video-react .video-react-play-control': {
        cursor: 'pointer',
        WebkitBoxFlex: 'none',
        MozBoxFlex: 'none',
        WebkitFlex: 'none',
        MsFlex: 'none',
        flex: 'none'
    },

    '.video-react .video-react-play-progress': {
        backgroundColor: '#fff'
    },

    '.video-react .video-react-big-play-button': {
        fontSize: '3em',
        lineHeight: '1.5em',
        height: '1.5em',
        width: '3em',
        display: 'block',
        position: 'absolute',
        top: '10px',
        left: '10px',
        padding: '0',
        cursor: 'pointer',
        opacity: '1',
        border: '0.06666em solid #fff',
        backgroundColor: 'rgba(43, 51, 63, 0.7)',
        WebkitBorderRadius: '0.3em',
        MozBorderRadius: '0.3em',
        borderRadius: '0.3em',
        WebkitTransition: 'all 0.4s',
        MozTransition: 'all 0.4s',
        OTransition: 'all 0.4s',
        transition: 'all 0.4s'
    },

    '.video-react-icon-play-arrow::before': {
        content: '""',
    },

    '.video-react .video-react-bezel .video-react-bezel-icon-play::before': {
        content: '""',
    },

    '.video-react .video-react-play-control::before': {
        content: '""',
    },

    '.video-react-icon-play-circle-filled::before': {
        content: '""',
    },

    '.video-react-icon-play-circle-outline::before': {
        content: '""',
    },

    '.video-react-icon-pause::before': {
        content: '""',
    },

    '.video-react .video-react-bezel .video-react-bezel-icon-pause::before': {
        content: '""',
    },

    '.video-react .video-react-play-control-video-react-playing::before': {
        content: '""',
    },

    '.video-react-icon-pause-circle-filled::before': {
        content: '""',
    },

    '.video-react-icon-pause-circle-outline::before': {
        content: '""',
    },

    '.video-react-icon-stop::before': {
        content: '""',
    },

    '.video-react-icon-fast-rewind::before': {
        content: '""',
    },

    '.video-react .video-react-bezel .video-react-bezel-icon-fast-rewind::before': {
        content: '""',
    },

    '.video-react-icon-fast-forward::before': {
        content: '""',
    },

    '.video-react .video-react-bezel .video-react-bezel-icon-fast-forward::before': {
        content: '""',
    },

    '.video-react-icon-skip-previous::before': {
        content: '""',
    },

    '.video-react-icon-skip-next::before': {
        content: '""',
    },

    '.video-react-icon-replay-5::before': {
        content: '""',
    },

    '.video-react .video-react-bezel .video-react-bezel-icon-replay-5::before': {
        content: '""',
    },

    '.video-react-icon-replay-10::before': {
        content: '""',
    },

    '.video-react .video-react-bezel .video-react-bezel-icon-replay-10::before': {
        content: '""',
    },

    '.video-react-icon-replay-30::before': {
        content: '""',
    },

    '.video-react .video-react-bezel .video-react-bezel-icon-replay-30::before': {
        content: '""',
    },

    '.video-react-icon-forward-5::before': {
        content: '""',
    },

    '.video-react .video-react-bezel .video-react-bezel-icon-forward-5::before': {
        content: '""',
    },

    '.video-react-icon-forward-10::before': {
        content: '""',
    },

    '.video-react .video-react-bezel .video-react-bezel-icon-forward-10::before': {
        content: '""',
    },

    '.video-react-icon-forward-30::before': {
        content: '""',
    },

    '.video-react .video-react-bezel .video-react-bezel-icon-forward-30::before': {
        content: '""',
    },

    '.video-react-icon-volume-off::before': {
        content: '""',
    },

    '.video-react .video-react-bezel .video-react-bezel-icon-volume-off::before': {
        content: '""',
    },

    '.video-react .video-react-mute-control-video-react-vol-muted::before': {
        content: '""',
    },

    '.video-react .video-react-volume-menu-button-video-react-vol-muted::before': {
        content: '""',
    },

    '.video-react-icon-volume-mute::before': {
        content: '""',
    },

    '.video-react .video-react-mute-control-video-react-vol-0::before': {
        content: '""',
    },

    '.video-react .video-react-volume-menu-button-video-react-vol-0::before': {
        content: '""',
    },

    '.video-react-icon-volume-down::before': {
        content: '""',
    },

    '.video-react .video-react-bezel .video-react-bezel-icon-volume-down::before': {
        content: '""',
    },

    '.video-react .video-react-mute-control-video-react-vol-2::before': {
        content: '""',
    },

    '.video-react .video-react-volume-menu-button-video-react-vol-2::before': {
        content: '""',
    },

    '.video-react .video-react-mute-control-video-react-vol-1::before': {
        content: '""',
    },

    '.video-react .video-react-volume-menu-button-video-react-vol-1::before': {
        content: '""',
    },

    '.video-react-icon-volume-up::before': {
        content: '""',
    },

    '.video-react .video-react-bezel .video-react-bezel-icon-volume-up::before': {
        content: '""',
    },

    '.video-react .video-react-mute-control::before': {
        content: '""',
    },

    '.video-react .video-react-volume-menu-button::before': {
        content: '""',
    },

    '.video-react-icon-fullscreen::before': {
        content: '""',
    },

    '.video-react-icon-fullscreen-exit::before': {
        content: '""',
    },

    '.video-react-icon-closed-caption::before': {
        content: '""',
    },

    '.video-react .video-react-closed-caption::before': {
        content: '""',
    },

    '.video-react-icon-hd::before': {
        content: '""',
    },

    '.video-react-icon-settings::before': {
        content: '""',
    },

    '.video-react-icon-share::before': {
        content: '""',
    },

    '.video-react-icon-info::before': {
        content: '""',
    },

    '.video-react-icon-info-outline::before': {
        content: '""',
    },

    '.video-react-icon-close::before': {
        content: '""',
    },

    '.video-react-icon-circle::before': {
        content: '""',
    },

    '.video-react .video-react-volume-level::before': {
        position: 'absolute',
        fontSize: '0.9em'
    },

    '.video-react .video-react-play-progress::before': {
        zIndex: '1'
    },

    '.video-react-icon-circle-outline::before': {
        content: '""',
    },

    '.video-react-icon-circle-inner-circle::before': {
        content: '""',
    },

    '.video-react': {
        boxSizing: 'inherit'
    },

    '.video-react .moz-full-screen': {
        position: 'absolute',
    },

    '.video-react .webkit-full-screen': {
        width: '100% !important',
        height: '100% !important'
    },

    '.video-react::before': {
        boxSizing: 'inherit'
    },

    '.video-react::after': {
        boxSizing: 'inherit'
    },

    '.video-react-ul': {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        listStylePosition: 'outside',
        marginLeft: '0',
        marginRight: '0',
        marginTop: '0',
        marginBottom: '0'
    },

    '.video-react-video-react-fluid': {
        width: '100%',
        maxWidth: '100%',
        height: '0'
    },

    '.video-react-video-react-16-9': {
        paddingTop: '56.25%'
    },

    '.video-react-video-react-4-3': {
        paddingTop: '75%'
    },

    '.video-react-video-react-fill': {
        width: '100%',
        height: '100%'
    },

    '.video-react .video-react-video': {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%'
    },

    '.video-react-video-react-fullscreen': {
        position: 'fixed',
        left: '0',
        top: '0',
        bottom: '0',
        right: '0',
        zIndex: '9999'
    },

    '.video-react-video-react-fullscreen-video-react-user-inactive': {
        cursor: 'none'
    },

    'body-video-react-full-window': {
        padding: '0',
        margin: '0',
        height: '100%',
        overflowY: 'auto'
    },

    'body-video-react-full-window .video-react-fullscreen': {
        position: 'fixed',
        overflow: 'hidden',
        zIndex: '1000',
        left: '0',
        top: '0',
        bottom: '0',
        right: '0'
    },

    '.video-react-button': {
        background: 'none',
        border: 'none',
        color: 'inherit',
        display: 'inline-block',
        cursor: 'pointer',
        overflow: 'visible',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        textTransform: 'none',
        textDecoration: 'none',
        transition: 'none',
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        appearance: 'none'
    },

    '.video-react .video-react-loading-spinner': {
        display: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        margin: '-25px 0 0 -25px',
        opacity: '0.85',
        textAlign: 'left',
        border: '6px solid rgba(43, 51, 63, 0.7)',
        boxSizing: 'border-box',
        backgroundClip: 'padding-box',
        width: '50px',
        height: '50px',
        borderRadius: '25px'
    },

    '.video-react .video-react-loading-spinner::before': {
        content: '""',
        position: 'absolute',
        margin: '-6px',
        boxSizing: 'inherit',
        width: 'inherit',
        height: 'inherit',
        borderRadius: 'inherit',
        opacity: '1',
        border: 'inherit',
        borderColor: 'transparent',
        borderTopColor: 'white',
        WebkitAnimation: '.video-react-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, video-react-spinner-fade 1.1s linear infinite',
        animation: '.video-react-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, video-react-spinner-fade 1.1s linear infinite'
    },

    '.video-react .video-react-loading-spinner-after': {
        content: '""',
        position: 'absolute',
        margin: '-6px',
        boxSizing: 'inherit',
        width: 'inherit',
        height: 'inherit',
        borderRadius: 'inherit',
        opacity: '1',
        border: 'inherit',
        borderColor: 'transparent',
        borderTopColor: 'white',
        WebkitAnimation: '.video-react-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, video-react-spinner-fade 1.1s linear infinite',
        animation: '.video-react-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, video-react-spinner-fade 1.1s linear infinite'
    },

    '.video-react-seeking .video-react-loading-spinner': {
        display: 'block'
    },

    '.video-react-waiting .video-react-loading-spinner': {
        display: 'block'
    },

    '.video-react-seeking .video-react-loading-spinner::before': {
        borderTopColor: 'white'
    },

    '.video-react-waiting .video-react-loading-spinner::before': {
        borderTopColor: 'white'
    },

    '.video-react-seeking .video-react-loading-spinner-after': {
        borderTopColor: 'white',
        WebkitAnimationDelay: '0.44s',
        animationDelay: '0.44s'
    },

    '.video-react-waiting .video-react-loading-spinner-after': {
        borderTopColor: 'white',
        WebkitAnimationDelay: '0.44s',
        animationDelay: '0.44s'
    },

    '.video-react .video-react-big-play-button-video-react-big-play-button-center': {
        top: '50%',
        left: '50%',
        marginTop: '-0.75em',
        marginLeft: '-1.5em'
    },

    '.video-react .video-react-big-play-button-big-play-button-hide': {
        display: 'none'
    },

    '.video-react-hover .video-react-big-play-button': {
        outline: '0',
        borderColor: '#fff',
        backgroundColor: 'rgba(115, 133, 159, 0.5)',
        WebkitTransition: 'all 0s',
        MozTransition: 'all 0s',
        OTransition: 'all 0s',
        transition: 'all 0s'
    },

    '.video-react .video-react-big-play-button-focus': {
        outline: '0',
        borderColor: '#fff',
        backgroundColor: 'rgba(115, 133, 159, 0.5)',
        WebkitTransition: 'all 0s',
        MozTransition: 'all 0s',
        OTransition: 'all 0s',
        transition: 'all 0s'
    },

    '.video-react-menu-button': {
        cursor: 'pointer'
    },

    '.video-react-menu-button-video-react-disabled': {
        cursor: 'default'
    },

    '.video-react-menu .video-react-menu-content': {
        display: 'block',
        padding: '0',
        margin: '0',
        overflow: 'auto',
    },

    '.video-react-menu-li': {
        listStyle: 'none',
        margin: '0',
        padding: '0.2em 0',
        lineHeight: '1.4em',
        fontSize: '1.2em',
        textAlign: 'center'
    },

    '.video-react-menu-li-focus': {
        outline: '0',
        backgroundColor: 'rgba(115, 133, 159, 0.5)'
    },

    '.video-react-menu-li-hover': {
        outline: '0',
        backgroundColor: 'rgba(115, 133, 159, 0.5)'
    },

    '.video-react-menu-li-video-react-selected': {
        backgroundColor: '#fff',
        color: '#2B333F'
    },

    '.video-react-menu-li-video-react-selected-focus': {
        backgroundColor: '#fff',
        color: '#2B333F'
    },

    '.video-react-menu-li-video-react-selected-hover': {
        backgroundColor: '#fff',
        color: '#2B333F'
    },

    '.video-react-menu-li-vjs-menu-title': {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: '1em',
        lineHeight: '2em',
        padding: '0',
        margin: '0 0 0.3em 0',
        fontWeight: 'bold',
        cursor: 'default'
    },

    '.video-react-scrubbing .vjs-menu-button-hover .video-react-menu': {
        display: 'none'
    },

    '.video-react .video-react-menu-button-popup .video-react-menu': {
        display: 'none',
        position: 'absolute',
        bottom: '0',
        width: '10em',
        left: '-3em',
        height: '0em',
        marginBottom: '1.5em',
        borderTopColor: 'rgba(43, 51, 63, 0.7)'
    },

    '.video-react .video-react-menu-button-popup .video-react-menu .video-react-menu-content': {
        backgroundColor: 'rgba(43, 51, 63, 0.7)',
        position: 'absolute',
        width: '100%',
        bottom: '1.5em',
        maxHeight: '15em'
    },

    '.video-react-menu-button-popup .video-react-menu-video-react-lock-showing': {
        display: 'block'
    },

    '.video-react .video-react-menu-button-inline': {
        WebkitTransition: 'all 0.4s',
        MozTransition: 'all 0.4s',
        OTransition: 'all 0.4s',
        transition: 'all 0.4s',
        overflow: 'hidden'
    },

    '.video-react .video-react-menu-button-inline::before': {
        width: '2.222222222em'
    },

    '.video-react .video-react-menu-button-inline-hover': {
        width: '12em'
    },

    '.video-react .video-react-menu-button-inline-focus': {
        width: '12em'
    },

    '.video-react .video-react-menu-button-inline-video-react-slider-active': {
        WebkitTransition: 'none',
        MozTransition: 'none',
        OTransition: 'none',
        transition: 'none'
    },

    '.video-react .video-react-menu-button-inline-hover .video-react-menu': {
        display: 'block',
        opacity: '1'
    },

    '.video-react .video-react-menu-button-inline-focus .video-react-menu': {
        display: 'block',
        opacity: '1'
    },

    '.video-react .video-react-menu-button-inline-video-react-slider-active .video-react-menu': {
        display: 'block',
        opacity: '1'
    },

    '.video-react .video-react-menu-button-inline .video-react-menu': {
        opacity: '0',
        height: '100%',
        width: 'auto',
        position: 'absolute',
        left: '4em',
        top: '0',
        padding: '0',
        margin: '0',
        WebkitTransition: 'all 0.4s',
        MozTransition: 'all 0.4s',
        OTransition: 'all 0.4s',
        transition: 'all 0.4s'
    },

    '.video-react .video-react-menu-button-inline .video-react-menu-content': {
        width: 'auto',
        height: '100%',
        margin: '0',
        overflow: 'hidden'
    },

    '.video-react-no-flex .video-react-menu-button-inline .video-react-menu': {
        display: 'block',
        opacity: '1',
        position: 'relative',
        width: 'auto'
    },

    '.video-react-no-flex .video-react-menu-button-inline-hover': {
        width: 'auto'
    },

    '.video-react-no-flex .video-react-menu-button-inline-focus': {
        width: 'auto'
    },

    '.video-react-no-flex .video-react-menu-button-inline-video-react-slider-active': {
        width: 'auto'
    },

    '.video-react .video-react-poster': {
        display: 'inline-block',
        verticalAlign: 'middle',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        backgroundSize: 'contain',
        backgroundColor: '#000000',
        cursor: 'pointer',
        margin: '0',
        padding: '0',
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        height: '100%'
    },

    '.video-react .video-react-poster-img': {
        display: 'block',
        verticalAlign: 'middle',
        margin: '0 auto',
        maxHeight: '100%',
        padding: '0',
        width: '100%'
    },

    '.video-react .video-react-slider': {
        outline: '0',
        position: 'relative',
        cursor: 'pointer',
        padding: '0',
        margin: '0 0.45em 0 0.45em',
        backgroundColor: 'rgba(115, 133, 159, 0.5)'
    },

    '.video-react .video-react-slider-focus': {
        WebkitBoxShadow: '0 0 1em #fff',
        MozBoxShadow: '0 0 1em #fff',
        boxShadow: '0 0 1em #fff'
    },

    '.video-react .video-react-control': {
        outline: 'none',
        position: 'relative',
        textAlign: 'center',
        margin: '0',
        padding: '0',
        height: '100%',
        width: '4em',
        WebkitBoxFlex: 'none',
        MozBoxFlex: 'none',
        WebkitFlex: 'none',
        MsFlex: 'none',
        flex: 'none'
    },

    '.video-react .video-react-control-focus::before': {
        textShadow: '0em 0em 1em #fff, 0em 0em 0.5em #fff'
    },

    '.video-react .video-react-control-hover::before': {
        textShadow: '0em 0em 1em #fff, 0em 0em 0.5em #fff'
    },

    '.video-react .video-react-control-focus': {
        textShadow: '0em 0em 1em #fff, 0em 0em 0.5em #fff'
    },

    '.video-react .video-react-control-text': {
        border: '0',
        clip: 'rect(0 0 0 0)',
        height: '1px',
        margin: '-1px',
        overflow: 'hidden',
        padding: '0',
        position: 'absolute',
        width: '1px'
    },

    '.video-react-no-flex .video-react-control': {
        display: 'table-cell',
        verticalAlign: 'middle'
    },

    '.video-react .video-react-control-bar': {
        display: 'none',
        width: '100%',
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
        height: '3em',
        backgroundColor: 'rgba(43, 51, 63, 0.7)'
    },

    '.video-react-has-started .video-react-control-bar': {
        display: 'flex',
        visibility: 'visible',
        opacity: '1',
        WebkitTransition: 'visibility 0.1s, opacity 0.1s',
        MozTransition: 'visibility 0.1s, opacity 0.1s',
        OTransition: 'visibility 0.1s, opacity 0.1s',
        transition: 'visibility 0.1s, opacity 0.1s'
    },

    '.video-react-has-started-video-react-user-inactive-video-react-playing .video-react-control-bar-video-react-control-bar-auto-hide': {
        visibility: 'visible',
        opacity: '0',
        WebkitTransition: 'visibility 1s, opacity 1s',
        MozTransition: 'visibility 1s, opacity 1s',
        OTransition: 'visibility 1s, opacity 1s',
        transition: 'visibility 1s, opacity 1s'
    },

    '.video-react-controls-disabled .video-react-control-bar': {
        display: 'none !important'
    },

    '.video-react-using-native-controls .video-react-control-bar': {
        display: 'none !important'
    },

    '.video-react-error .video-react-control-bar': {
        display: 'none !important'
    },

    '.video-react-audio-video-react-has-started-video-react-user-inactive-video-react-playing .video-react-control-bar': {
        opacity: '1',
        visibility: 'visible'
    },

    '.video-react-has-started-video-react-no-flex .video-react-control-bar': {
        display: 'table'
    },

    '.video-react .video-react-progress-control': {
        WebkitBoxFlex: 'auto',
        MozBoxFlex: 'auto',
        WebkitFlex: 'auto',
        MsFlex: 'auto',
        flex: 'auto',
        display: 'flex',
        WebkitBoxAlign: 'center',
        WebkitAlignItems: 'center',
        MsFlexAlign: 'center',
        alignItems: 'center',
        minWidth: '4em'
    },

    '.video-react-live .video-react-progress-control': {
        display: 'none'
    },

    '.video-react .video-react-progress-holder': {
        WebkitBoxFlex: 'auto',
        MozBoxFlex: 'auto',
        WebkitFlex: 'auto',
        MsFlex: 'auto',
        flex: 'auto',
        WebkitTransition: 'all 0.2s',
        MozTransition: 'all 0.2s',
        OTransition: 'all 0.2s',
        transition: 'all 0.2s',
        height: '0.3em'
    },

    '.video-react .video-react-progress-control-hover .video-react-progress-holder': {
        fontSize: '1.6666666667em'
    },

    '.video-react .video-react-progress-control-hover .video-react-time-tooltip': {
        visibility: 'visible',
        fontSize: '0.6em'
    },

    '.video-react .video-react-progress-control-hover .video-react-mouse-display-after': {
        visibility: 'visible',
        fontSize: '0.6em'
    },

    '.video-react .video-react-progress-control-hover .video-react-play-progress-after': {
        visibility: 'visible',
        fontSize: '0.6em'
    },

    '.video-react .video-react-progress-holder .video-react-play-progress': {
        position: 'absolute',
        display: 'block',
        height: '0.3em',
        margin: '0',
        padding: '0',
        width: '0',
        left: '0',
        top: '0'
    },

    '.video-react .video-react-progress-holder .video-react-load-progress': {
        position: 'absolute',
        display: 'block',
        height: '0.3em',
        margin: '0',
        padding: '0',
        width: '0',
        left: '0',
        top: '0'
    },

    '.video-react .video-react-progress-holder .video-react-tooltip-progress-bar': {
        position: 'absolute',
        display: 'block',
        height: '0.3em',
        margin: '0',
        padding: '0',
        width: '0',
        left: '0',
        top: '0'
    },

    '.video-react .video-react-progress-holder .video-react-load-progress-div': {
        position: 'absolute',
        display: 'block',
        height: '0.3em',
        margin: '0',
        padding: '0',
        width: '0',
        left: '0',
        top: '0'
    },

    '.video-react .video-react-time-tooltip': {
        display: 'inline-block',
        height: '2.4em',
        position: 'relative',
        float: 'right',
        right: '-1.9em'
    },

    '.video-react .video-react-mouse-display-after': {
        visibility: 'hidden',
        pointerEvents: 'none',
        position: 'absolute',
        top: '-3.4em',
        right: '-1.9em',
        fontSize: '0.9em',
        color: '#000',
        content: '""',
        padding: '6px 8px 8px 8px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        WebkitBorderRadius: '0.3em',
        MozBorderRadius: '0.3em',
        borderRadius: '0.3em'
    },

    '.video-react .video-react-play-progress-after': {
        zIndex: '1'
    },

    '.video-react .video-react-progress-control .video-react-keep-tooltips-inside-after': {
        display: 'none'
    },

    '.video-react .video-react-load-progress': {
        background: 'rgba(115, 133, 159, 0.5)'
    },

    '.video-react .video-react-load-progress-div': {
        background: 'rgba(115, 133, 159, 0.75)'
    },

    '.video-react-video-react-no-flex .video-react-progress-control': {
        width: 'auto'
    },

    '.video-react .video-react-tooltip-progress-bar': {
        visibility: 'hidden'
    },

    '.video-react .video-react-progress-control .video-react-mouse-display': {
        display: 'none',
        position: 'absolute',
        width: '1px',
        height: '100%',
        backgroundColor: '#000',
        zIndex: '1'
    },

    '.video-react-no-flex .video-react-progress-control .video-react-mouse-display': {
        zIndex: '0'
    },

    '.video-react .video-react-progress-control-hover .video-react-mouse-display': {
        display: 'block'
    },

    '.video-react-video-react-user-inactive .video-react-progress-control .video-react-mouse-display': {
        visibility: 'hidden',
        opacity: '0',
        WebkitTransition: 'visibility 1s, opacity 1s',
        MozTransition: 'visibility 1s, opacity 1s',
        OTransition: 'visibility 1s, opacity 1s',
        transition: 'visibility 1s, opacity 1s'
    },

    '.video-react-video-react-user-inactive .video-react-progress-control .video-react-mouse-display-after': {
        visibility: 'hidden',
        opacity: '0',
        WebkitTransition: 'visibility 1s, opacity 1s',
        MozTransition: 'visibility 1s, opacity 1s',
        OTransition: 'visibility 1s, opacity 1s',
        transition: 'visibility 1s, opacity 1s'
    },

    '.video-react-video-react-user-inactive-video-react-no-flex .video-react-progress-control .video-react-mouse-display': {
        display: 'none'
    },

    '.video-react-video-react-user-inactive-video-react-no-flex .video-react-progress-control .video-react-mouse-display-after': {
        display: 'none'
    },

    '.video-react .video-react-mouse-display .video-react-time-tooltip': {
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },

    '.video-react .video-react-progress-control .video-react-mouse-display-after': {
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },

    '.video-react .video-react-fullscreen-control': {
        cursor: 'pointer',
        WebkitBoxFlex: 'none',
        MozBoxFlex: 'none',
        WebkitFlex: 'none',
        MsFlex: 'none',
        flex: 'none'
    },

    '.video-react .video-react-time-control': {
        WebkitBoxFlex: 'none',
        MozBoxFlex: 'none',
        WebkitFlex: 'none',
        MsFlex: 'none',
        flex: 'none',
        fontSize: '1em',
        lineHeight: '3em',
        minWidth: '2em',
        width: 'auto',
        paddingLeft: '1em',
        paddingRight: '1em'
    },

    '.video-react .video-react-time-divider': {
        lineHeight: '3em',
        minWidth: 'initial',
        padding: '0'
    },

    '.video-react .video-react-volume-control': {
        width: '5em',
        WebkitBoxFlex: 'none',
        MozBoxFlex: 'none',
        WebkitFlex: 'none',
        MsFlex: 'none',
        flex: 'none',
        display: 'flex',
        WebkitBoxAlign: 'center',
        WebkitAlignItems: 'center',
        MsFlexAlign: 'center',
        alignItems: 'center'
    },

    '.video-react .video-react-volume-bar': {
        margin: '1.35em 0.45em'
    },

    '.video-react .video-react-volume-bar-video-react-slider-horizontal': {
        width: '5em',
        height: '0.3em'
    },

    '.video-react .video-react-volume-bar-video-react-slider-horizontal .video-react-volume-level': {
        width: '100%'
    },

    '.video-react .video-react-volume-bar-video-react-slider-vertical': {
        width: '0.3em',
        height: '5em',
        margin: '1.35em auto'
    },

    '.video-react .video-react-volume-bar-video-react-slider-vertical .video-react-volume-level': {
        height: '100%'
    },

    '.video-react .video-react-slider-vertical .video-react-volume-level': {
        width: '0.3em'
    },

    '.video-react .video-react-slider-vertical .video-react-volume-level::before': {
        top: '-0.5em',
        left: '-0.3em'
    },

    '.video-react .video-react-slider-horizontal .video-react-volume-level': {
        height: '0.3em'
    },

    '.video-react .video-react-slider-horizontal .video-react-volume-level::before': {
        top: '-0.3em',
        right: '-0.5em'
    },

    '.video-react .video-react-menu-button-popup-video-react-volume-menu-button .video-react-menu': {
        display: 'block',
        width: '0',
        height: '0',
        borderTopColor: 'transparent'
    },

    '.video-react .video-react-menu-button-popup-video-react-volume-menu-button-vertical .video-react-menu': {
        left: '0.5em',
        height: '8em'
    },

    '.video-react .video-react-menu-button-popup-video-react-volume-menu-button-horizontal .video-react-menu': {
        left: '-2em'
    },

    '.video-react .video-react-menu-button-popup-video-react-volume-menu-button .video-react-menu-content': {
        height: '0',
        width: '0',
        overflowX: 'hidden',
        overflowY: 'hidden'
    },

    '.video-react .video-react-volume-menu-button-vertical-hover .video-react-menu-content': {
        height: '8em',
        width: '2.9em'
    },

    '.video-react .video-react-volume-menu-button-vertical-focus .video-react-menu-content': {
        height: '8em',
        width: '2.9em'
    },

    '.video-react .video-react-volume-menu-button-vertical-video-react-slider-active .video-react-menu-content': {
        height: '8em',
        width: '2.9em'
    },

    '.video-react .video-react-volume-menu-button-vertical .video-react-lock-showing .video-react-menu-content': {
        height: '8em',
        width: '2.9em'
    },

    '.video-react .video-react-volume-menu-button-horizontal-hover .video-react-menu-content': {
        height: '2.9em',
        width: '8em'
    },

    '.video-react .video-react-volume-menu-button-horizontal-focus .video-react-menu-content': {
        height: '2.9em',
        width: '8em'
    },

    '.video-react .video-react-volume-menu-button-horizontal .video-react-slider-active .video-react-menu-content': {
        height: '2.9em',
        width: '8em'
    },

    '.video-react .video-react-volume-menu-button-horizontal .video-react-lock-showing .video-react-menu-content': {
        height: '2.9em',
        width: '8em'
    },

    '.video-react .video-react-volume-menu-button-video-react-menu-button-inline .video-react-menu-content': {
        backgroundColor: 'transparent !important'
    },

    '.video-react .video-react-playback-rate .video-react-playback-rate-value': {
        lineHeight: '3em',
        textAlign: 'center'
    },

    '.video-react .video-react-playback-rate .video-react-menu': {
        width: '4em',
        left: '0em'
    },

    '.video-react .video-react-bezel': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        width: '52px',
        height: '52px',
        zIndex: '17',
        marginLeft: '-26px',
        marginTop: '-26px',
        background: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '26px'
    },

    '.video-react .video-react-bezel-video-react-bezel-animation': {
        MozAnimation: '.video-react-bezel-fadeout 0.5s linear 1 normal forwards',
        WebkitAnimation: '.video-react-bezel-fadeout 0.5s linear 1 normal forwards',
        animation: '.video-react-bezel-fadeout 0.5s linear 1 normal forwards',
        pointerEvents: 'none'
    },

    '.video-react .video-react-bezel-video-react-bezel-animation-alt': {
        MozAnimation: '.video-react-bezel-fadeout-alt 0.5s linear 1 normal forwards',
        WebkitAnimation: '.video-react-bezel-fadeout-alt 0.5s linear 1 normal forwards',
        animation: '.video-react-bezel-fadeout-alt 0.5s linear 1 normal forwards',
        pointerEvents: 'none'
    },

    '.video-react-video .-webkit-media-text-track-container': {
        WebkitTransform: 'translateY(-30px)',
        transform: 'translateY(-30px)'
    }
}
