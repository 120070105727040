export default ({
    spaces,
    colors,
    helpers,
}) => ({
    key: { name: 'PlayerSeeker' },
    
    track: {
        width: '100%',
        height: 60,
        position: 'relative',
    },

    trackInner: {
        display: 'block',
        width: '100%',
        height: 3,
        position: 'absolute',
        top: '50%',
        left: 0,
        marginTop: -1.5,
        zIndex: 2,
        backgroundColor: helpers.hexToRgba(colors.white, .25),
        pointerEvents: 'none',
    },

    trackerProgress: {
        display: 'block',
        width: '100%',
        height: 3,
        position: 'absolute',
        top: '50%',
        left: 0,
        marginTop: -1.5,
        zIndex: 2,
        backgroundColor: colors.black,
        pointerEvents: 'none',
    },

    thumb: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 60,
        width: 30,
        zIndex: '3!important',

        '&::before': {
            display: 'block',
            width: 3,
            height: 3,
            backgroundColor: colors.black,
            '-webkit-clip-path': 'polygon(0% 0%, 50% 0%, 100% 100%, 0% 100%)',
            'clip-path': 'polygon(0% 0%, 50% 0%, 100% 100%, 0% 100%)',
            content: '""',
        },
    },
})
