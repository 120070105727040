import React, { createContext, useState, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useBodyClass } from "../helpers"

const MenuContext = createContext()

/**
 * MenuProvider
 *
 * @param {mixed} children
 */
const MenuProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query MenuProviderQuery {
      wp {
        tiltingPointSettings {
          optionsPanel {
            enquiryEmail
            mailchimp
            socialLinks {
              youtube {
                url
              }
              facebook {
                url
              }
              twitter {
                url
              }
              instagram {
                url
              }
              linkedin {
                url
              }
            }
            locations {
              name
              address
              active {
                sourceUrl
              }
              inactive {
                sourceUrl
              }
            }
            partners {
              title
              logo {
                sourceUrl
                mediaDetails {
                  sizes {
                    name
                    sourceUrl
                  }
                }
              }
            }
            quotes {
              quote
              name
            }
          }
        }
      }
    }
  `)

  const [open, setOpen] = useState(false)

  useBodyClass(open ? "menu-open" : "")

  // useEffect(() => {
  //     const handleResize = throttle(() => setOpen(false), 1000)

  //     window.addEventListener('resize', handleResize)
  //     return () => window.removeEventListener('resize', handleResize)
  // }, [])

  return (
    <MenuContext.Provider
      value={{
        header: [
          { label: "What we do", slug: "/what-we-do/" },
          { label: "Who we are", slug: "/who-we-are/" },
          { label: "Our Games", slug: "/games/" },
          { label: "News", slug: "/news/" },
          { label: "Careers", slug: "/careers/" },
          { label: "Contact", slug: "/contact/" },
        ],
        footer: [],
        options: data?.wp?.tiltingPointSettings?.optionsPanel ?? {},
        menuOpen: open,
        setMenuOpen: setOpen,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

/**
 * useMenuState
 */
const useMenuState = () => {
  const context = useContext(MenuContext)

  if (context === undefined)
    throw new Error("useMenuState must be used within a MenuProvider")

  return context
}

export { MenuProvider, useMenuState }
