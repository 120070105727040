import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import Cookies from 'js-cookie'
import is from 'react-jss'

import { useCookieSettingsState } from '../../context'

import Button from '../Button'
import Settings from './components/Settings'

import style from './style'

const CookieBanner = ({
    classes,
}) => {
    const [ showBanner, setShowBanner ] = useState(false)
    const { open, setOpen } = useCookieSettingsState()
    const location = useLocation()

    useEffect(() => {
        setShowBanner(Cookies.get('gatsby-gdpr-responded') !== 'true')
    }, [])

    useEffect(() => {
        initTracking()
    }, [Cookies.get('gatsby-gdpr-responded')])

    const initTracking = () => {
        initializeAndTrack(location)
    }

    const handleAccept = () => {
        Cookies.set('gatsby-gdpr-google-analytics', true, { expires: 365 })
        handleCloseAll()
    }
    
    const handleDecline = () => {
        Cookies.remove('gatsby-gdpr-google-analytics')
        handleCloseAll()
    }

    const handleCloseAll = () => {
        setOpen(false)
        setShowBanner(false)

        Cookies.set('gatsby-gdpr-responded', true, { expires: 365 })
    }

    return [
        <div key="banner" className={cx(classes.banner, { active: showBanner && !open })}>
            <div className={classes.inner}>
                <div>
                    <h5 className={classes.heading}>Our site uses cookies and similar technologies. Some are essential and some help us improve your experience on this site. Please click ‘accept’ to accept all cookies. Please click ‘decline’ to keep all cookies off except those that are necessary.</h5>
                </div>
                <div className={classes.bottom}>
                    <div className={classes.buttons}>
                        <Button color="black" clickHandler={() => handleAccept()} className={classes.button}>Accept</Button>
                        <Button clickHandler={() => handleDecline()} className={classes.button}>Decline</Button>
                    </div>
                    <button className={classes.link} onClick={() => setOpen(true)}>Learn more</button>
                </div>
            </div>
        </div>,
        open && <Settings key="settings" { ...{ handleAccept, handleDecline, initTracking, handleCloseAll } } />,
    ]
}

export default is(style)(CookieBanner)
