import tpEot from "../../../assets/fonts/tp.eot"
import tpTtf from "../../../assets/fonts/tp.ttf"
import tpWoff from "../../../assets/fonts/tp.woff"
import tpSvg from "../../../assets/fonts/tp.svg"
import zpThin from "../../../assets/fonts/zp-thin.otf"
import zpBold from "../../../assets/fonts/zp-bold.otf"

export default {
    '@font-face': [
        {
            fontFamily: 'Tilting Point',
            src: [
                `url(${tpEot})`,
                `url(${tpEot}) format('embedded-opentype')`,
                `url(${tpTtf}) format('truetype')`,
                `url(${tpWoff}) format('woff')`,
                `url(${tpSvg}) format('svg')`,
            ],
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontDisplay: 'block',
        },
        {
            fontFamily: 'Zona Pro',
            src: [
                `url(${zpThin}) format('opentype')`,
            ],
        },
        {
            fontFamily: 'Zona Pro',
            fontWeight: 'bold',
            src: [
                `url(${zpBold}) format('opentype')`,
            ],
        },
    ],

    'svg:not(:root).svg-inline--fa': {
        overflow: 'visible',
    },
    
    '.svg-inline--fa': {
        display: 'inline-block',
        fontSize: 'inherit',
        height: '1em',
        overflow: 'visible',
        verticalAlign: '-.125em',
    },
    
    '.svg-inline--fa.fa-lg': {
        verticalAlign: '-.225em',
    },
    
    '.svg-inline--fa.fa-w-1': {
        width: '0.0625em',
    },
    
    '.svg-inline--fa.fa-w-2': {
        width: '0.125em',
    },
    
    '.svg-inline--fa.fa-w-3': {
        width: '0.1875em',
    },
    
    '.svg-inline--fa.fa-w-4': {
        width: '0.25em',
    },
    
    '.svg-inline--fa.fa-w-5': {
        width: '0.3125em',
    },
    
    '.svg-inline--fa.fa-w-6': {
        width: '0.375em',
    },
    
    '.svg-inline--fa.fa-w-7': {
        width: '0.4375em',
    },
    
    '.svg-inline--fa.fa-w-8': {
        width: '0.5em',
    },
    
    '.svg-inline--fa.fa-w-9': {
        width: '0.5625em',
    },
    
    '.svg-inline--fa.fa-w-10': {
        width: '0.625em',
    },
    
    '.svg-inline--fa.fa-w-11': {
        width: '0.6875em',
    },
    
    '.svg-inline--fa.fa-w-12': {
        width: '0.75em',
    },
    
    '.svg-inline--fa.fa-w-13': {
        width: '0.8125em',
    },
    
    '.svg-inline--fa.fa-w-14': {
        width: '0.875em',
    },
    
    '.svg-inline--fa.fa-w-15': {
        width: '0.9375em',
    },
    
    '.svg-inline--fa.fa-w-16': {
        width: '1em',
    },
    
    '.svg-inline--fa.fa-w-17': {
        width: '1.0625em',
    },
    
    '.svg-inline--fa.fa-w-18': {
        width: '1.125em',
    },
    
    '.svg-inline--fa.fa-w-19': {
        width: '1.1875em',
    },
    
    '.svg-inline--fa.fa-w-20': {
        width: '1.25em',
    },
    
    '.svg-inline--fa.fa-pull-left': {
        marginRight: '.3em',
        width: 'auto',
    },
    
    '.svg-inline--fa.fa-pull-right': {
        marginLeft: '.3em',
        width: 'auto',
    },
    
    '.svg-inline--fa.fa-border': {
        height: '1.5em',
    },
    
    '.svg-inline--fa.fa-li': {
        width: '2em',
    },
    
    '.svg-inline--fa.fa-fw': {
        width: '1.25em',
    },
    
    '.fa-layers svg.svg-inline--fa': {
        bottom: 0,
        left: 0,
        margin: 'auto',
        position: 'absolute',
        right: 0,
        top: 0,
    },
    
    '.fa-layers': {
        display: 'inline-block',
        height: '1em',
        position: 'relative',
        textAlign: 'center',
        verticalAlign: '-.125em',
        width: '1em',
    },
    
    '.fa-lg': {
        fontSize: '1.33333em',
        lineHeight: '0.75em',
        verticalAlign: '-.0667em',
    },
    
    '.fa-xs': {
        fontSize: '.75em',
    },
    
    '.fa-sm': {
        fontSize: '.875em',
    },
    
    '.fa-1x': {
        fontSize: '1em',
    },
    
    '.fa-2x': {
        fontSize: '2em',
    },
    
    '.fa-3x': {
        fontSize: '3em',
    },
    
    '.fa-4x': {
        fontSize: '4em',
    },
    
    '.fa-5x': {
        fontSize: '5em',
    },
    
    '.fa-6x': {
        fontSize: '6em',
    },
    
    '.fa-7x': {
        fontSize: '7em',
    },
    
    '.fa-8x': {
        fontSize: '8em',
    },
    
    '.fa-9x': {
        fontSize: '9em',
    },
    
    '.fa-10x': {
        fontSize: '10em',
    },
    
    '.fa-fw': {
        textAlign: 'center',
        width: '1.25em',
    },
}
