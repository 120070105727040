import { styles } from '../../../config'

const {
    buttons,
    fonts,
    grid,
    player,
    variables,
} = styles

const {
    colors,
    fontstacks,
    helpers,
    spaces,
    textAlign,
    typography,
    breakpoints,
} = variables

export default ({
    '@global': {
        ...fonts,
        '*': {
            boxSizing: 'border-box',
            flex: '0 1 auto',
        },
        'html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video': {
            margin: 0,
            padding: 0,
            border: 0,
            outline: 0,
            fontSize: '100%',
            verticalAlign: 'baseline',
            background: 'transparent',
        },
        html: {
            height: '100%',
            fontFamily: fontstacks.primary,
            lineHeight: 1.5,
            '-webkit-text-size-adjust': '100%',
        },
        body: {
			margin: 0,
            background: colors.white,
            color: colors.black,
        },
        'article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section': {
            display: 'block',
        },
        nav: {
            ul: {
                listStyle: 'none',
            },
        },
        'blockquote, q': {
            quotes: 'none',
        },
        'blockquote:before, blockquote:after, q:before, q:after': {
            content: '',
            content: 'none',
        },
        img: {
            maxWidth: '100%',
            height: 'auto',
        },
        'h1, .h1': {
            ...typography.h1,
        },
        'h2, .h2': {
            ...typography.h2,
        },
        'h3, .h3': {
            ...typography.h3,
        },
        'h4, .h4': {
            ...typography.h4,
        },
        p: {
            ...typography.p,
        },
        'small, .small': {
            ...typography.small,
        },
        'ol, ul': {
            ...typography.p,
            paddingLeft: 20,

            '&:last-of-type, &:last-child': {
                marginBottom: [spaces[1]],
            },
        },
        'li': {
            listStyleType: 'none',
            position: 'relative',

            '&::before': {
                content: '"\\00b7"',
                position: 'absolute',
                left: -20,
                lineHeight: '20px',
                fontSize: 100,
            },
        },
        a: {
            margin: 0,
            padding: 0,
            fontSize: '100%',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            background: 'transparent',
            color: colors.white,

            '&:hover, &:active': {
                textDecoration: 'none',
                color: colors.tertiary,
            },
        },
        ins: {
            backgroundColor: '#ff9',
            color: colors.black,
            textDecoration: 'none',
        },
        mark: {
            backgroundColor: '#ff9',
            color: colors.black,
            fontStyle: 'italic',
            fontWeight: 'bold',
        },
        del: {
            textDecoration: 'line-through',
        },
        'abbr[title], dfn[title]': {
            borderBottom: '1px dotted',
            cursor: 'help',
        },
        strong: {
            fontWeight: 600,
        },
        table: {
            borderCollapse: 'collapse',
            borderSpacing: 0,
        },
        hr: {
            display: 'block',
            height: 1,
            border: 0,
            borderTop: '1px solid #cccccc',
            margin: '1em 0',
            padding: 0,
        },
        'input, label, select, button, textarea': {
            margin: 0,
            border: 0,
            padding: 0,
            display: 'inline-block',
            verticalAlign: 'middle',
            whiteSpace: 'normal',
            background: 'none',
            lineHeight: 1,
            fontFamily: `${fontstacks.primary} !important`,
            fontWeight: 200,
            fontStyle: 'normal!important',
            resize: 'none',
            outline: 0,
        },
        'input, select': {
            '&:focus': {
                outline: 0,
            },
        },
        'input[type=checkbox], input[type=radio]': {
            width: 13,
            height: 13,
        },
        'input[type=search]': {
            '-webkit-appearance': 'textfield',
            '-webkit-boxSizing': 'content-box',
        },
        '::-webkit-search-decoration': {
            display: 'none',
        },
        form: {
            width: '100%',
            maxWidth: '100%',
        },
        'button, input[type=reset], input[type=button], input[type=submit]': {
            overflow: 'visible',
            width: 'auto',
        },
        '::-webkit-file-upload-button': {
            padding: 0,
            border: 0,
            background: 'none',
        },
        textarea: {
            verticalAlign: 'top',
            overflow: 'auto',
            fontFamily: `${fontstacks.primary} !important`,
            fontWeight: "200!important",
            fontStyle: 'normal!important',
        },
        '::-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder, ::-ms-input-placeholder': {
            fontFamily: `${fontstacks.primary} !important`,
            fontWeight: "200!important",
            fontStyle: 'normal!important',
            color: `${colors.black}!important`,
        },
        'select[multiple]': {
            verticalAlign: 'top',
        },
        '.screen-reader-text': {
            display: 'none',
            textIndent: -9999,
        },
        '.page-content': {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            overflow: 'hidden',
        },
        '#content': {
            width: '100%',
            overflow: 'hidden',
        },

        '.icon': {
            fontSize: 30,
            verticalAlign: 'middle',
            transition: 'all ease-in-out .15s',

            '&.small': {
                fontSize: 24,
            },
        },

        '.gradient-text': {
            display: 'inline-block',
            ...helpers.gradientText,
        },

        '[class^="tp-icon-"], [class*=" tp-icon-"]': {
            fontFamily: 'Tilting Point !important',
            speak: 'none',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontVariant: 'normal',
            textTransform: 'none',
            lineHeight: 1,
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
        },

        '.tp-icon-video-close:before': {
            content: '"\\e900"',
        },
        '.tp-icon-video-fullscreen:before': {
            content: '"\\e901"',
        },
        '.tp-icon-video-play-full:before': {
            content: '"\\e902"',
        },
        '.tp-icon-video-play:before': {
            content: '"\\e903"',
        },
        '.tp-icon-video-volume:before': {
            content: '"\\e904"',
        },
        '.tp-icon-video-pause:before': {
            content: '"\\e905"',
        },
        '#root': {
            overflowX: 'hidden',
        },
        '.wysiwyg': {
            '& h1, & h2, & h3, & h4, & h5, & h6': {
                marginTop: spaces[3],
    
                [breakpoints.sm]: {
                    marginTop: spaces[4],
                },
            },
    
            '& h1, & h2': {
                marginTop: spaces[4],
    
                [breakpoints.sm]: {
                    marginTop: spaces[5],
                },
            },
    
            '& h1': {
                marginBottom: spaces[3],
                fontSize: 45,
                lineHeight: '47px',
        
                [breakpoints.sm]: {
                    fontSize: 54,
                    lineHeight: '56px',
                },
            },
    
            '& h2': {
                fontSize: 30,
                lineHeight: '32px',
            },
    
            '& h3, & h4, & h5, & h6': {
                fontSize: 20,
                lineHeight: '22px',
            },
    
            '& ul, & ol': {
                margin: [0, 0, spaces[3]],
            },

            '& table': {
                width: '100%',
                minWidth: '50%',
                marginTop: spaces[3],
                borderBottom: `1px solid ${colors.black}`,
                borderRight: `1px solid ${colors.black}`,
                textAlign: 'left',
    
                '& th, & td': {
                    padding: spaces[1],
                    borderTop: `1px solid ${colors.black}`,
                    borderLeft: `1px solid ${colors.black}`,
    
                    '& a': {
                        fontWeight: 'bold',
                        color: colors.primary,
                    },
                },
            },
        },

        ...textAlign,
        ...grid,
        ...buttons,
        ...player,

        '.open-cookie-banner': {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: [spaces[3], 0, 0],
            height: 62,
            padding: [0, spaces[2]],
            border: `2px solid ${colors.black}`,
            background: colors.black,
            color: colors.white,
            fontSize: 14,
            textTransform: 'uppercase',
            fontWeight: 700,
            clear: 'both',

            '&:hover': {
                backgroundColor: 'transparent',
                color: colors.black,
            },
        },
    },
})