import variables from './variables'
import helpers from './helpers'

const {
    colors,
} = variables

export default {
    radial: {
        top: {
            primary: `radial-gradient(center top, ellipse cover, ${helpers.hexToRgba(colors.primary, 1)} 0%, ${helpers.hexToRgba(colors.primary, 0)} 65%)`,
            secondary: `radial-gradient(center top, ellipse cover, ${helpers.hexToRgba(colors.secondary, 1)} 0%, ${helpers.hexToRgba(colors.secondary, 0)} 65%)`,
            tertiary: `radial-gradient(center top, ellipse cover, ${helpers.hexToRgba(colors.tertiary, 1)} 0%, ${helpers.hexToRgba(colors.tertiary, 0)} 65%)`,
    
            white: `radial-gradient(center top, ellipse cover, ${helpers.hexToRgba(colors.white, 1)} 0%, ${helpers.hexToRgba(colors.white, 0)} 65%)`,
            black: `radial-gradient(center top, ellipse cover, ${helpers.hexToRgba(colors.black, 1)} 0%, ${helpers.hexToRgba(colors.black, 0)} 65%)`,
    
            pink: `radial-gradient(center top, ellipse cover, ${helpers.hexToRgba(colors.pink, 1)} 0%, ${helpers.hexToRgba(colors.pink, 0)} 65%)`,
            blue: `radial-gradient(center top, ellipse cover, ${helpers.hexToRgba(colors.blue, 1)} 0%, ${helpers.hexToRgba(colors.blue, 0)} 65%)`,
            yellow: `radial-gradient(center top, ellipse cover, ${helpers.hexToRgba(colors.yellow, 1)} 0%, ${helpers.hexToRgba(colors.yellow, 0)} 65%)`,
        },

        bottom: {
            primary: `radial-gradient(center bottom, ellipse cover, ${helpers.hexToRgba(colors.primary, 1)} 0%, ${helpers.hexToRgba(colors.primary, 0)} 65%)`,
            secondary: `radial-gradient(center bottom, ellipse cover, ${helpers.hexToRgba(colors.secondary, 1)} 0%, ${helpers.hexToRgba(colors.secondary, 0)} 65%)`,
            tertiary: `radial-gradient(center bottom, ellipse cover, ${helpers.hexToRgba(colors.tertiary, 1)} 0%, ${helpers.hexToRgba(colors.tertiary, 0)} 65%)`,
    
            white: `radial-gradient(center bottom, ellipse cover, ${helpers.hexToRgba(colors.white, 1)} 0%, ${helpers.hexToRgba(colors.white, 0)} 65%)`,
            black: `radial-gradient(center bottom, ellipse cover, ${helpers.hexToRgba(colors.black, 1)} 0%, ${helpers.hexToRgba(colors.black, 0)} 65%)`,
    
            pink: `radial-gradient(center bottom, ellipse cover, ${helpers.hexToRgba(colors.pink, 1)} 0%, ${helpers.hexToRgba(colors.pink, 0)} 65%)`,
            blue: `radial-gradient(center bottom, ellipse cover, ${helpers.hexToRgba(colors.blue, 1)} 0%, ${helpers.hexToRgba(colors.blue, 0)} 65%)`,
            yellow: `radial-gradient(center bottom, ellipse cover, ${helpers.hexToRgba(colors.yellow, 1)} 0%, ${helpers.hexToRgba(colors.yellow, 0)} 65%)`,
        },
    },
}
