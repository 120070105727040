export default ({
    spaces,
    breakpoints,
	colors,
}) => ({
    key: { name: 'Header' },
    
    header: {
        height: 0,
    },

    background: {
        display: 'block',
        width: '100%',
        height: 100,
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: colors.white,
        zIndex: 49,
        transform: 'translate3d(0, -102%, 0)',
        transition: 'all ease-in-out .25s',

        '.scrollUp &, .navOpen &': {
            transform: 'translate3d(0, 0, 0)',
        },

        '.atTop &': {
            display: 'none',
        },

        [breakpoints.md]: {
            display: 'none',
        },
    },

    logo: {
        width: 180,
        position: 'fixed',
        top: spaces[5]/2,
        left: spaces[3],
        transform: 'translateY(-50%)',
        zIndex: 50,
        transition: 'margin .35s ease',

        [breakpoints.sm]: {
            width: 200,
        },
        
        [breakpoints.md]: {
            width: 240,
            top: spaces[4],
        },

        [breakpoints.mw.md]: {
            '$header:not(.atTop) &': {
                marginTop: -100,
            },

            '$header.scrollUp:not(.atTop) &, .navOpen &': {
                marginTop: '0!important',
            },
        },
    },

    button: {
        position: 'fixed',
        top: spaces[5]/2,
        right: spaces[3],
        transform: 'translateY(-50%)',
        zIndex: 50,
        transition: 'margin .35s ease',

        [breakpoints.md]: {
            top: spaces[4],
        },
        
        '&:hover': {
            '& $menuIcon': {
                '& > span': {
                    backgroundColor: `${colors.white}!important`,
                },
            },
        },

        [breakpoints.mw.md]: {
            '$header:not(.atTop) &': {
                marginTop: -100,
            },

            '$header.scrollUp:not(.atTop) &, .navOpen &': {
                marginTop: '0!important',
            },
        },
    },

    menuIcon: {
		display: 'block',
		transition: 'all .15s ease',

        [breakpoints.md]: {
            display: 'none',
        },

        '& > span': {
            display: 'block',
            width: 23,
            height: 3,
            marginTop: 5,
            backgroundColor: colors.black,
            transition: 'all ease-in-out .15s',

            '&:first-child': {
                marginTop: 0,
                transformOrigin: '0 0'
            },

            '&:last-child': {
                transformOrigin: '0 100%',
            },
        },

        '&.active': {
            '& > span': {
                '&:first-child': {
                    transform: 'translate(5px, 0) rotate(48deg)',
                },
    
                '&:nth-child(2)': {
                    opacity: 0,
                    visibility: 'hidden',
                },
    
                '&:last-child': {
                    transform: 'translate(5px, 0) rotate(-48deg)',
                },
            },
        },
    },

    menuLabel: {
		display: 'none',
		transition: 'all .15s ease',

        [breakpoints.md]: {
            display: 'block',
        }
	},

	show: {},
})