import '../../../assets/font-awesome.css'
import '../../../assets/video-react.css'

import fontawesome from '../../../config/fontawesome'
fontawesome()

import React, { Fragment } from 'react'
import is from 'react-jss'
import { ThemeProvider } from 'react-jss'

import { ContextProvider, useBrowserState } from '../../context'
import { styles } from '../../../config'

import Footer from '../Footer'
import Header from '../Header'
import NavMenu from '../NavMenu'
import Player from '../Player'
// import CookieBanner from '../CookieBannerOld'
import CookieBanner from '../CookieBanner'

import style from './style'

const Root = ({ children }) => {
    const { browser, isMobile, isMobileOnly, isTablet } = useBrowserState()

    return (
        <ThemeProvider theme={{
            browser,
            isMobile,
            isMobileOnly,
            isTablet,
            ...styles.variables,
        }}>
            <Fragment>
                <NavMenu />
                <Player />
                <Header />
                { children }
                <CookieBanner />
                <Footer />
            </Fragment>
        </ThemeProvider>
    )
}

const Layout = ({ children }) => (
    <ContextProvider>
        <Root {...{ children }} /> 
    </ContextProvider>
)

export default is(style)(Layout)
