export default ({
    spaces,
    typography,
    colors,
    breakpoints,
    gradients,
}) => ({
    banner: {
        display: 'none',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: `calc(100% - ${spaces[0]*2}px)`,
        padding: [spaces[2], spaces[3]],
        position: 'fixed',
        bottom: spaces[0],
        right: spaces[0],
        textAlign: 'center',
        backgroundColor: colors.blue,
        overflow: 'hidden',
        color: colors.white,
        zIndex: 9999,

        '&.active': {
            display: 'flex',
        },

        [breakpoints.sm]: {
            maxWidth: 560,
            textAlign: 'left',
        },

        [breakpoints.md]: {
            bottom: spaces[1],
            right: spaces[1],
        },

        [breakpoints.lg]: {
            bottom: spaces[2],
            right: spaces[2],
        },

        '&::before': {
            display: 'block',
            width: '200%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            background: gradients.radial.top.secondary,
            content: '""',
            pointerEvents: 'none',
        },
    },

    inner: {
        position: 'relative',
        zIndex: 9999,
    },

    heading: {
        maxWidth: 1000,
        ...typography.small,
        margin: [0, 0, spaces[2]],
        color: colors.white,
    },

    bottom: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: [spaces[1], 0, 0],

        [breakpoints.sm]: {
            flexDirection: 'row',
        },
    },

    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    link: {
        ...typography.p,
        margin: [spaces[2], 0, 0],
        whiteSpace: 'nowrap',
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.white,
        cursor: 'pointer',

        [breakpoints.sm]: {
            margin: [0, 0, 0, spaces[2]],
        },
    },
})