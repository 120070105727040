import React, { createContext, useState, useContext } from 'react'

const CookieSettingsContext = createContext()

/**
 * CookieSettingsProvider
 * 
 * @param {mixed} children
 */
const CookieSettingsProvider = ({ children }) => {
    const [ open, setOpen ] = useState(false)

	return (
		<CookieSettingsContext.Provider value={{
            open,
            setOpen,
        }}>
			{ children }
		</CookieSettingsContext.Provider>
	)
}

/**
 * useCookieSettingsState
 */
const useCookieSettingsState = () => {
	const context = useContext(CookieSettingsContext)

	if (context === undefined) throw new Error('useCookieSettingsState must be used within a CookieSettingsProvider')

	return context
}

export { CookieSettingsProvider, useCookieSettingsState }
