import React from 'react'
import { findDOMNode } from 'react-dom'
import cx from 'classnames'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'
import is from 'react-jss'

import VolumeSlider from '../VolumeSlider'
import Seeker from '../Seeker'

import style from './style'

const Duration = ({ className, seconds }) => (
    <time dateTime={`P${Math.round(seconds)}S`} className={className}>{ formatTime(seconds) }</time>
)

const formatTime = (seconds) => {
    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = padTime(date.getUTCSeconds())

    if (hh) {
        return `${hh}:${padTime(mm)}:${ss}`
    }

    return `${mm}:${ss}`
}

const padTime = (string) => {
    return (`0${string}`).slice(-2)
}

const Controls = ({
    classes,
    player,
    showControls,
    wrapper,
	url, setUrl,
	pip, setPip,
	playing, setPlaying,
	ended, setEnded,
	light, setLight,
	volume, setVolume,
	muted, setMuted,
    played, setPlayed,
    duration,
    loaded,
    seeking, setSeeking,
    controls, setControls,
	loop, setLoop,
}) => {
	const playpause = () => {
		setPlaying(!playing)
	}

	const stop = () => {
		setUrl(null)
		setPlaying(false)
    }
    
    const restart = () => {
        setPlayed(0)
        setPlaying(true)
        setEnded(false)
    }

	const handleClickFullscreen = () => {
        screenfull.isFullscreen ? screenfull.exit() : screenfull.request(findDOMNode(wrapper))
    }

    return [
        <div key="overlay" className={classes.overlay} onClick={ (e) => playpause() } />,
        <div key="controls" className={ cx(classes.controls, { show: showControls }) }>
            <div className={classes.playpause}>
                { ended ? (
                    <button onClick={ (e) => restart() } className={ cx(classes.squareButton, classes.icon, 'restart') } />
                ) : (
                    <button onClick={ (e) => playpause() } className={ cx(classes.squareButton, classes.icon, playing ? 'pause' : 'play') } />
                ) }
            </div>
            <div className={classes.volume}>
                <button onClick={ (e) => setMuted(!muted) } className={ cx(classes.squareButton, classes.icon, muted ? 'unmute' : 'mute') } />
                <VolumeSlider { ...{ volume, setVolume, setMuted, muted } } />
            </div>
            <div className={classes.progress}>
                <span>
                    <Duration seconds={ parseFloat(played * duration).toFixed(2) } /> / 
                    <Duration seconds={duration} />
                </span>
            </div>
            <div className={classes.seek}>
                <Seeker { ...{ player, setPlaying, setSeeking, played, setPlayed } } />
            </div>
            <div className={classes.fullscreen}>
                <button onClick={ () => handleClickFullscreen() } className={ cx(classes.squareButton, classes.icon, 'fullscreen') } />
            </div>
        </div>
    ]
}

export default is(style)(Controls)
