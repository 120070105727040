import variables from './variables'

const {
    spaces,
    colors,
    breakpoints,
} = variables

export default {
    '.btn': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 62,
        margin: [0, 18], // account for the scew background
        transform: 'skewX(30deg)',
        backgroundColor: colors.white,
        transition: 'transform ease-in-out .1s, color ease-in-out .1s',

        '&:not(.no-hover)': {
            cursor: 'pointer',
            
            '&:hover': {
                transform: 'skewX(10deg) scale(.9)',

                '& .btn__inner': {
                    transform: 'skewX(-10deg) scale(1.1)',
                    background: colors.primary,
                    background: `-moz-linear-gradient(45deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
                    background: `-webkit-linear-gradient(45deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
                    background: `linear-gradient(45deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
                    filter: `progid:DXImageTransform.Microsoft.gradient(startColorstr="${colors.primary}", endColorstr="${colors.secondary}", GradientType=1)`,
                    color: 'transparent',
                    '-webkit-background-clip': 'text',
                    backgroundClip: 'text',
                },
            },
        },

        '&--white': {
            backgroundColor: colors.white,
            color: colors.black,

            '&:not(.no-hover):hover': {
                color: colors.primary,
            },
        },
    },

    '.btn__inner': {
        display: 'inline-block',
        position: 'relative',
        transform: 'skewX(-30deg)',
        padding: [0, spaces[2]],
        textTransform: 'uppercase',
        fontWeight: 700,
        transition: 'transform ease-in-out .1s',
		zIndex: 2,
		
		margin: 0,

        [breakpoints.md]: {
            padding: [0, spaces[3]],
        },
    },
}
