import React, { useState } from 'react'
import cx from 'classnames'
import Cookies from 'js-cookie'
import is from 'react-jss'

import Link from '../../../Link'
import Button from '../../../Button'
import Toggle from '../../../Toggle'

import style from './style'

const CookieSettings = ({
    classes,
    handleAccept,
    handleDecline,
    initTracking,
    handleCloseAll,
}) => {
    const [ trackAnalytics, setTrackAnalytics ] = useState(Cookies.get('gatsby-gdpr-google-analytics') === 'true')

    const handleToggle = () => {
        Cookies.set('gatsby-gdpr-responded', true, { expires: 365 })

        setTrackAnalytics((prevState) => {
            if (prevState) {
                Cookies.remove('gatsby-gdpr-google-analytics')
            } else {
                Cookies.set('gatsby-gdpr-google-analytics', true, { expires: 365 })
            }

            return !prevState
        })

        initTracking()
    }

    return (
        <div className={classes.settings}>
            <div className={classes.inner}>
                <h2 className={classes.heading}>This site uses cookies to store information on your computer.</h2>
                {/* <div className={classes.body}>
                    <p>Some of these cookies are essential, while others help us to improve your experience by providing insights into how the site is being used.</p>
                    <p>You can find out how to manage your preferences in relation to our use of cookies at Cookie Policy</p>
                </div> */}

                {/* <div className={classes.buttons}>
                    <Button color="black" clickHandler={() => handleAccept()} className={classes.button}>Accept</Button>
                    <Button clickHandler={() => handleDecline()} className={classes.button}>Decline</Button>
                </div> */}

                <div className={classes.types}>
                    <div className={classes.type}>
                        <div className={classes.typeHeader}>
                            <h4 className={classes.typeHeading}>Essential Cookies</h4>
                        </div>
                        <p className={classes.typeDetails}>Essential cookies enable core functionality such as page navigation and access to secure areas. The website cannot function properly without these cookies, and can only be disabled by changing your browser preferences.</p>
                    </div>

                    <div className={classes.type}>
                        <div className={classes.typeHeader}>
                            <h4 className={classes.typeHeading}>Analytical Cookies</h4>
                            <Toggle active={trackAnalytics} toggleActive={() => handleToggle()} />
                        </div>
                        <p className={classes.typeDetails}>Analytical cookies are non-essential cookies which help us to improve our website by collecting and reporting information on its usage. We use these cookies so we can better understand how you engage with our website, marketing campaigns and products. The information we collect is anonymised.</p>
                    </div>
                </div>

                <p className={classes.privacy}>You may wish to review our <a href="/privacy-policy" target="_blank" rel="noreferrer noopener" className={classes.privacyLink}>Privacy Policy</a>, which provides more information on cookies in general.</p>

                <Button clickHandler={() => handleCloseAll()} className={cx(classes.button, 'save')}>Confirm my choice</Button>
            </div>
        </div>
    )
}

export default is(style)(CookieSettings)
