import React, { useState } from "react"
import is from "react-jss"
import cx from "classnames"

import { useMenuState } from "../../context"

import Link from "../Link"
import Slide from "./components/Slide"

import style from "./style"

const slideImages = [
  [
    "https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092451/whatwedo-closed.jpg",
    "https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092449/whatwedo-active.jpg",
  ],
  [
    "https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092441/contact-closed.jpg",
    "https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092440/contact-active.jpg",
  ],
  [
    "https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092448/ourgames-closed.jpg",
    "https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092447/ourgames-active.jpg",
  ],
  [
    "https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092445/news-closed.jpg",
    "https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092444/news-active.jpg",
  ],
  [
    "https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092438/careers-closed.jpg",
    "https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092437/careers-active.jpg",
  ],
  [
    "https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092441/contact-closed.jpg",
    "https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092440/contact-active.jpg",
  ],
  [
    "https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092441/contact-closed.jpg",
    "https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092440/contact-active.jpg",
  ],
]

const NavMenu = ({ classes, ...props }) => {
  const { header, menuOpen, setMenuOpen } = useMenuState()
  const handleClick = () => {
    setMenuOpen(false)
  }

  return header.length > 0 ? (
    <div
      className={cx(classes.navMenu, {
        [classes.show]: menuOpen,
      })}
    >
      <div className={classes.slideContainer}>
        <div className={classes.slideWrapper}>
          {header.map((m, i) => (
            <Slide
              key={i}
              images={slideImages[i % slideImages.length]}
              {...{ ...m, handleClick }}
            />
          ))}
        </div>
      </div>

      <div className={cx(classes.menuList)}>
        {header.map((m, i) => (
          <span className={cx(classes.link)} key={i}>
            <Link to={m.slug} clickHandler={handleClick}>
              {m.label}
            </Link>
          </span>
        ))}
      </div>
    </div>
  ) : null
}

export default is(style)(NavMenu)
