import React from "react"
import cx from "classnames"
import { useLocation } from "@reach/router"
import LazyLoad from "react-lazyload"
import is from "react-jss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import mediumIcon from "../../../assets/images/medium.svg"
import mailchimpIcon from "../../../assets/images/mailchimp.svg"
import winnerIcon from "../../../assets/images/winnerIcon.png"
import googleIcon from "../../../assets/images/googleIcon.jpg"
import facebookIcon from "../../../assets/images/facebook-new.png"
import top50 from "../../../assets/images/top50.jpg"

import { useMenuState } from "../../context"

import Link from "../Link"

import style from "./style"

const Footer = ({ classes }) => {
  const {
    options: {
      socialLinks: { facebook, instagram, linkedin, twitter, youtube },
    },
  } = useMenuState()

  const location = useLocation()

  // Ideally suppress social links is a boolean option on the footer.
  // Hard coded for now.
  const suppressSocialLinks = [
    "privacy-policy",
    "app-ads-txt",
    "ccpa",
    "covered-services",
    "child-privacy-policy",
    "operators-list",
    "creatorterms",
    "data-recipients",
    "game-governance",
    "privacy-policy",
    "privacy-policy-aa",
    "terms-of-service",
    "general-terms",
  ].some(slug => location.pathname.includes(slug))
  return (
    <div className={classes.footer}>
      <div className="container container--wide">
        <div className={cx("row align-middle", classes.footerItems)}>
          <div
            className={cx(
              !suppressSocialLinks
                ? "col-xs-12 col-md-6 col-lg-3 tac md-tar lg-tal"
                : "col-xs-12 tac",
              classes.column
            )}
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <Link to="/">
              <LazyLoad offset={200} height={50} style={{ width: "auto" }}>
                <img
                  src="https://cdn-tiltingpoint-website.tiltingpoint.io/static/logo-dark.png"
                  alt="Tilting Point"
                  className={classes.logo}
                />
              </LazyLoad>
            </Link>
          </div>
          {!suppressSocialLinks ? (
            <div
              className={cx(
                "col-xs-12 col-md-12 col-lg-2 tac",
                classes.columnImages
              )}
              style={{ padding: 0 }}
            >
              <LazyLoad
                offset={200}
                height={90}
                style={{ display: "flex", width: "100%" }}
                className={classes.loadImages}
              >
                <img
                  src={top50}
                  className={classes.pgbiz}
                  style={{ marginRight: "10px" }}
                />
                <img
                  src={winnerIcon}
                  alt=""
                  className={classes.pgbiz}
                  style={{ marginLeft: "10px" }}
                />
              </LazyLoad>
            </div>
          ) : (
            ""
          )}
          {!suppressSocialLinks ? (
            <div
              className={cx(
                "col-xs-12 col-md-6 col-lg-2 tac md-tal lg-tar",
                classes.column,
                classes.socials
              )}
            >
              <>
                {youtube && (
                  <a
                    href={youtube.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className={classes.icon}
                  >
                    <FontAwesomeIcon icon={["fab", "youtube"]} color="black" />
                  </a>
                )}
                {linkedin && (
                  <a
                    href={linkedin.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className={classes.icon}
                  >
                    <FontAwesomeIcon icon={["fab", "linkedin"]} color="black" />
                  </a>
                )}
                {facebook && (
                  <a
                    href={facebook.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className={classes.icon}
                  >
                    <FontAwesomeIcon
                      icon={["fab", "facebook-square"]}
                      color="black"
                    />
                  </a>
                )}
                {twitter && (
                  <a
                    href={twitter.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className={classes.icon}
                  >
                    <FontAwesomeIcon icon={["fab", "twitter"]} color="black" />
                  </a>
                )}
                {instagram && (
                  <a
                    href={instagram.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className={classes.icon}
                  >
                    <FontAwesomeIcon
                      icon={["fab", "instagram"]}
                      color="black"
                    />
                  </a>
                )}
                <a
                  className={classes.icon}
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://tiltingpoint.medium.com/"
                >
                  <img src={mediumIcon} alt="" width={20} height={20} />
                </a>
                <a
                  className={classes.icon}
                  target="_blank"
                  rel="noreferrer noopener"
                  href="http://eepurl.com/g26V8v"
                >
                  <img src={mailchimpIcon} alt="" width={20} height={20} />
                </a>
              </>
            </div>
          ) : (
            ""
          )}
          <div
            className={cx(
              !suppressSocialLinks ? "col-xs-12 col-lg-3 tac" : "col-xs-12 tac",
              classes.column
            )}
          >
            <div className={classes.linksContainer}>
              <div>
                <div className={classes.links}>
                  <p className={classes.copyright}>
                    Tilting Point {"\u00A9"} 2023. All Rights Reserved.
                  </p>
                </div>
                <div className={classes.links}>
                  <Link to="/privacy-policy/" className={classes.link}>
                    Privacy
                    <br /> Policy
                  </Link>
                  <Link
                    to="/terms-of-service/"
                    className={classes.link}
                    style={{ marginLeft: "40px" }}
                  >
                    Terms of <br />
                    Service
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {!suppressSocialLinks ? (
            <div className={cx("col-xs-12 col-lg-2 tac", classes.awards)}>
              <>
                <LazyLoad
                  offset={200}
                  height={65}
                  style={{ marginRight: "2rem" }}
                >
                  <img src={googleIcon} alt="" className={classes.facebook} />
                </LazyLoad>
                <LazyLoad offset={200} height={65}>
                  <img
                    src={facebookIcon}
                    alt="Facebook Marketing Partner"
                    className={classes.facebook}
                  />
                </LazyLoad>
              </>
            </div>
          ) : (
            ""
          )}{" "}
        </div>
      </div>
    </div>
  )
}

export default is(style)(Footer)
