import React from 'react'
import VisibilitySensor from 'react-visibility-sensor'

const withIsVisible = Component => ({
	...props
}) => (
	<VisibilitySensor {...{ scrollCheck: true, partialVisibility: true }}>
		{ ({ isVisible }) => <Component {...{ isVisible, ...props }} /> }
	</VisibilitySensor>
)

export default withIsVisible