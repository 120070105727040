import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import htmlParser from 'react-html-parser'
import is from 'react-jss'

import style from './style'

const RandomText = ({
    classes,
    speed = 50,
    letters = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz',
    animate = false,
    className = '',
	full = false,
	alwaysShow = false,
	delay = 0,
	useOwnLetters = false,
    children,
}) => {
    const [ loaded, setLoaded ] = useState(false)
    const [ index, setIndex ] = useState(0)
    const [ displayText, setDisplayText ] = useState(full ? children : '')
    const max = htmlParser(children)[0].length
	const pool = useOwnLetters
		? [
			...htmlParser(children)[0].replace(/\W/g, '').toLowerCase().split(''),
			...htmlParser(children)[0].replace(/\W/g, '').toUpperCase().split(''),
		].join('')
		: letters.split('')
	

    useEffect(() => {
        setLoaded(true)
	}, [])

    const generateText = () => {
        let letters = htmlParser(children)[0].split('')
        let jumbledText = ''

        for (let i = 0; i <= (letters.length - 2); i++) {
            if (typeof letters[i] !== 'undefined') {
				if (i > index && !full) continue

                jumbledText += (/^[a-z0-9]+$/i.test(letters[i]) === false || i < (index) || letters[i] === ' ') ? letters[i] : pool[Math.floor(Math.random() * pool.length)]
            }
		}

        setDisplayText(jumbledText)
        setIndex((prevState) => prevState+1)
    }

    useEffect(() => {
        if (animate) {
            let interval = null
            if (index < (max)) {
                interval = setInterval(() => {
                    generateText()
                }, speed)
            } else {
				setDisplayText(children)
                clearInterval(interval)
            }
    
            return () => clearInterval(interval)
        } else {
			setIndex(0)
			setDisplayText(full ? children : '')
        }
    }, [index, animate])

    return (
        <span
            className={ cx(
                classes.text,
                className, {
                    [classes.full]: full,
                    [classes.loaded]: loaded,
                    [classes.animated]: animate,
                    [classes.alwaysShow]: alwaysShow,
                }
            ) }
        >
            { htmlParser(displayText) }
        </span>
    )
}

export default is(style)(RandomText)
