import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({
    className,
    to,
    children,
    external = false,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
}) => {

    const handleClick = (e) => {
        if (typeof clickHandler === 'function') clickHandler(e)
    }

    if (external) {
        return <a
            href={ to }
            target="_blank"
            rel="noreferrer noopener"
            className={ className }
        >
            { children }
        </a>
    } else {
        return <GatsbyLink {...{ to, className, onMouseEnter, onMouseLeave, onClick: handleClick }}>{ children }</GatsbyLink>
    }

    // return (
    //     <a
    //         href={ to === '/home/' ? '/' : to }
    //         className={className}
    //         onClick={ (e) => {
    //             if ((!e.ctrlKey && !e.metaKey) && !external) {
    //                 e.preventDefault()
                    
    //                 if (!isPending) {
    //                     startTransition(() => {
    //                         history.push(to === '/home/' ? '/' : to)
    //                         if (typeof clickHandler === 'function') clickHandler(e)
    //                     })
    //                 }
    //             }
    //         }}
    //         {...external ? { target: '_blank', rel: 'noopener noreferrer' } : {}}
    //         {...{ onMouseEnter, onMouseLeave }}
    //     >{ children }</a>
    // )
}

export default Link