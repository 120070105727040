import { useEffect, useState } from 'react'

import withIsVisible from './withIsVisible'

// const ReactLazyPreload = importStatement => {
//     const Component = lazy(importStatement)
//     Component.preload = importStatement
//     return Component
// }

// const isServer = () => typeof window === "undefined" ? true : false

const addBodyClass = className => className !== '' && document.body.classList.add(className)
const removeBodyClass = className => className !== '' && document.body.classList.remove(className)

const useBodyClass = (className) => {
    useEffect(() => {
        className instanceof Array ? className.map(addBodyClass) : addBodyClass(className)
        return () => className instanceof Array ? className.map(removeBodyClass) : removeBodyClass(className)
    }, [className])
}

// const usePrevious = (value) => {
// 	const ref = useRef()

// 	useEffect(() => {
// 		ref.current = value
// 	})

// 	return ref.current
// }

const getImageSourceUrl = (background, size = 'large') => {

    let image = background?.sourceUrl ?? background?.mediaItemUrl ?? false

    // let image = sizes[0] && sizes[0].sourceUrl
    
    if (background?.mediaDetails?.sizes?.length > 0) {
        for (let i = 0; i < background?.mediaDetails?.sizes?.length; i++) {
            const curImage = background?.mediaDetails?.sizes[i]
    
            if (typeof curImage.name !== 'undefined' && typeof curImage.sourceUrl !== 'undefined') {
                if (curImage.name === size) {
                    image = curImage.sourceUrl
                }
            }
        }
    }

	return image
}

const displayDate = (value) => {
	let d = new Date(value)
	let months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]

	return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`
}

const debounce = (value, delay) => {
    // State and setters for debounced value
    const [ debouncedValue, setDebouncedValue ] = useState(value);
      
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        return () => {
            clearTimeout(handler);
        }
    }, [value])
  
    return debouncedValue;
}

export {
    // ReactLazyPreload,
    // isServer,
    useBodyClass,
    // usePrevious,
    withIsVisible,
	getImageSourceUrl,
	displayDate,
	debounce,
}