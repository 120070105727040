export default ({
	spaces,
    helpers,
	colors,
	fontstacks,
	breakpoints,
}) => ({
	key: { name: 'Player' },
	
    container: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: helpers.hexToRgba(colors.black, .75),
        zIndex: 99,
	},
	
	ready: {},

    inner: {
        width: '90vw',
		height: '50.625vw',
		maxHeight: '90vh',
		maxWidth: '160vh',
		margin: 'auto',
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: colors.black,

		// '$ready &': {
			// backgroundColor: 'transparent',
		// },
	},

	close: {
		width: 34,
		height: 34,
		position: 'absolute',
		right: spaces[2],
		top: spaces[2],
		fontSize: 30,
		backgroundColor: colors.white,
		borderRadius: spaces[0] / 2,
		cursor: 'pointer',
		zIndex: 30,
		
		'&::before': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
		},
		
		'&:hover': {
			backgroundColor: colors.primary,
			color: colors.white,
		},
	},
})