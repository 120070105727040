import React, { createContext, useState, useContext } from 'react'

const VideoContext = createContext()

/**
 * VideoProvider
 * 
 * @param {mixed} children
 */
const VideoProvider = ({ children }) => {
	const [ activeVideo, setActiveVideo ] = useState({})

    const enableVideoPlayer = ({
		video = false,
		poster = false,
	}) => {
		setActiveVideo({
			video,
			poster,
		})
	}
	const disableVideoPlayer = () => {
		setActiveVideo(null)
	}

	return (
		<VideoContext.Provider value={{
			activeVideo,
            enableVideoPlayer,
            disableVideoPlayer,
        }}>
			{ children }
		</VideoContext.Provider>
	)
}

/**
 * useVideoState
 */
const useVideoState = () => {
	const context = useContext(VideoContext)

	if (context === undefined) throw new Error('useVideoState must be used within a VideoProvider')

	return context
}

export { VideoProvider, useVideoState }
