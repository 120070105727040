import React from 'react'
import { Range } from 'react-range'
import is from 'react-jss'

import style from './style'

const VolumeSlider = ({
    classes,
    volume, setVolume,
    muted, setMuted,
}) => {
    const handleVolumeChange = (values) => {
        setVolume(parseFloat(values[0]))

        if (parseFloat(values[0]) === 0) {
            setMuted(true)
        } else if (muted === true) {
            setMuted(false)
        }
    }

    return (
        <Range
            step={0.025}
            min={0}
            max={1}
            values={[ volume ]}
            onChange={ (values) => handleVolumeChange(values) }
            renderTrack={ ({ props, children }) => (
                <div { ...props } className={classes.track}>
                    { children }
                    <div className={classes.trackInner} style={{ width: `${volume * 100}%` }} />
                </div>
            ) }
            renderThumb={ ({ props }) => (
                <div { ...props } className={classes.thumb} />
            ) }
        />
    )
}

export default is(style)(VolumeSlider)
