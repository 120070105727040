export default ({
	colors,
	breakpoints,
	typography,
	spaces,
	helpers,
}) => ({
	key: { name: 'NavMenu' },
	
    navMenu: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',

        backgroundColor: colors.tertiary,
        opacity: 0,

        transition: 'all .45s ease',
        zIndex: 11,
        pointerEvents: 'none',

        '&$show': {
            opacity: 1,
            pointerEvents: 'auto',
        },
    },

    slideContainer: {
		display: 'none',

		[breakpoints.md]: {
			display: 'flex',
			flexDirection: 'row',
			width: '80%',
			height: '200%',
			margin: 'auto',
			transform: 'translate3d(0, -25%, 0)',
		}
    },

    slideWrapper: {
		[breakpoints.md]: {
			display: 'flex',
			transform: 'translate3d(-50%, 0, 0)',
			position: 'absolute',
			left: '50%',
			height: '100%',
		},
	},
	
	menuList: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		paddingTop: 95,
		backgroundImage: 'url(https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/06/02141840/mobile-menu.jpg)',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',

		height: '100%',

		zIndex: 10,
		textAlign: 'center',

		'& > span': {
			...typography.h1,
		},

		'& a': {
			display: 'block',
			padding: [spaces[0], 0],
			color: colors.white,
			transition: 'all .15 ease',

			'&:hover': {
				color: colors.secondary,
			},
		},
		
		[breakpoints.md]: {
			display: 'none',
			pointerEvents: 'none',
		}
	},

    show: {},
})