export default ({
	spaces,
	breakpoints,
	helpers,
	colors,
	typography,
}) => ({
	key: { name: 'NavMenuSlide' },
	
    slideParent: {
		flexGrow: 1,
		minWidth: '20vw',
		transition: 'all ease .15s',
		// transitionDelay: '.2s',

		[breakpoints.lg]: {
			minWidth: '15vw',
		}
    },
    slide: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	
		width: '87.5%',
		height: '100%',
		margin: 'auto',
		transform: 'rotate3d(0, 0, 1, -30deg)',
		transformOrigin: '50% 50%',
		
		backgroundColor: colors.tertiary,
		transition: 'all ease .15s',

		cursor: 'pointer',
		overflow: 'hidden',
    },
    image: {
		position: 'fixed',
		width: '100vw',
		height: '100vh',
		top: '25%',
		transform: 'rotate3d(0, 0, 1, 30deg)',
		pointerEvents: 'none',
	},
	bgimage: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',

		opacity: 0,
		transition: 'all ease .15s',

		'&:first-child': {
			opacity: 1,
		},
		
		// transitionDelay: '.2s',
	},

	label: {
		...typography.h3,

		zIndex: 11,
		backfaceVisibility: 'hidden',

		color: colors.white,
		textAlign: 'center',
		userSelect: 'none',
		writingMode: 'vertical-rl',
		textOrientation: 'mixed',

		margin: 0,

		transition: 'all ease .15s',
		// transitionDelay: '.2s',
	},

    hover: {
		'&$slideParent': {
			transition: 'all ease .15s',
			minWidth: '20vw',
			
			[breakpoints.lg]: {
				minWidth: '28vw',
			}
		},

		'& $bgimage': {
			opacity: 1,
			transform: 'scale3d(1.1, 1.1, 1)',
			transition: 'all ease .15s',
			// transitionDelay: '0s',
		},

		'& $label': {
			...typography.h1,
			color: colors.white,
	
			'.browser:not(.ie) &': {
				color: 'transparent',
				'-webkit-text-stroke-width': '1px',
				'-webkit-text-stroke-color': colors.white,
			},

			// transitionDelay: '0s',
		},
	},
})