import React from 'react'

import { BrowserProvider, useBrowserState } from './BrowserProvider'
import { MenuProvider, useMenuState } from './MenuProvider'
import { CookieSettingsProvider, useCookieSettingsState } from './CookieSettingsProvider'
// import { EnvironmentProvider, useEnvironmentState } from './EnvironmentProvider'
// import { CacheProvider, useCacheState } from './CacheProvider'
import { VideoProvider, useVideoState } from './VideoProvider'

const ContextProvider = ({
	children,
}) => (
	<BrowserProvider>
		<MenuProvider>
			<CookieSettingsProvider>
				<VideoProvider>
					{ children }
				</VideoProvider>
			</CookieSettingsProvider>
		</MenuProvider>
	</BrowserProvider>
)

export {
	ContextProvider,
	useBrowserState,
	useMenuState,
	useCookieSettingsState,
	// useEnvironmentState,
	useVideoState,
	// useCacheState,
}