import fonts from './fonts'
import grid from './grid'
import helpers from './helpers'
import textAlign from './textAlign'
import variables from './variables'
import buttons from './buttons'
import gradients from './gradients'
import player from './player'

const {
    fontstacks,
    spaces,
    breakpoints,
    fontSizes,
    colors,
} = variables

const typography = {
    h1: {
        marginBottom: '.09615em',
        fontFamily: fontstacks.primary,
        fontSize: 28,
        fontWeight: 'bold',
        lineHeight: 1.11538,
        textTransform: 'uppercase',

        [breakpoints.xs]: {
            fontSize: 'calc(.03394*100vw + 15.27273px)',
        },

        [breakpoints.xlg]: {
            fontSize: 56,
        },
    },
    h2: {
        marginBottom: '.625em',
        fontFamily: fontstacks.primary,
        fontSize: 25,
        fontWeight: 'bold',
        lineHeight: 1.11538,
        color: colors.black,
		textTransform: 'uppercase',
		
		[breakpoints.sm]: {
			fontSize: 'calc(.01576*100vw + 19.09091px)',
		},

        [breakpoints.xlg]: {
            fontSize: 38,
        }
    },
    h3: {
        marginBottom: 20,
        fontFamily: fontstacks.primary,
        fontSize: 24,
        fontWeight: 'bold',
        lineHeight: 1.11538,
        color: colors.black,
		textTransform: 'uppercase',
		
		[breakpoints.sm]: {
			fontSize: 'calc(.0097*100vw + 20.36364px)',
		},

        [breakpoints.xlg]: {
            fontSize: 32,
        },
    },
    h4: {
        marginBottom: 20,
        fontFamily: fontstacks.primary,
        fontSize: 15,
        fontWeight: 'bold',
        lineHeight: 1.2,
        color: colors.black,
		textTransform: 'uppercase',
		
		[breakpoints.sm]: {
			fontSize: 'calc(.00485*100vw + 14.18182px)',
		},

        [breakpoints.xlg]: {
            fontSize: 20,
        },
    },
    p: {
        margin: [0, 0, spaces[1]],
        fontSize: 16,
        fontWeight: 300,
		color: colors.darkgrey,
		
		[breakpoints.md]: {
			fontSize: 'calc(.00242*100vw + 15.09091px)',
		},

		[breakpoints.xlg]: {
			fontSize: 18,
		},

        '&:last-of-type, &:last-child': {
            marginBottom: 0,
        },
    },
    small: {
        fontSize: 12,
        fontWeight: 300,
		lineHeight: 1.46667,
		
		[breakpoints.sm]: {
			fontSize: 'calc(.00606*100vw + 7.72727px)',
		},

		[breakpoints.xlg]: {
			fontSize: 15,
		},
    },
}

const v = {
    breakpoints,
    colors,
    fontSizes,
    fontstacks,
    gradients,
    helpers,
    spaces,
    textAlign,
    typography,
}

export default {
    breakpoints,
    colors,
    fontSizes,
    fonts,
    fontstacks,
    grid,
    helpers,
    spaces,
    textAlign,
    typography,
    buttons,
    gradients,
    player,
    variables: v,
}
