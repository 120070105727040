import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as _ from 'underscore'
import cx from 'classnames'
import is from 'react-jss'

import { useMenuState } from '../../context'

import Button from '../Button'
import Link from '../Link'

import style from './style'

const Header = ({
    classes,
}) => {
    const { menuOpen, setMenuOpen } = useMenuState()

    const [ scrollDir, setScrollDir ] = useState(0)
    const [ scrollY, setScrollY ] = useState(0)

    const handleNavigation = _.throttle((e) => {
        const window = e.currentTarget

        if (window && window.scrollY) {
            setScrollY((prevState) => {
                if (window.scrollY > 0 && (prevState > window.scrollY)) {
                    if (scrollDir === 0) {
                        setScrollDir(1) // Up
                    }
                } else if (prevState > 0 && prevState < window.scrollY) {
                    if (scrollDir === 1) {
                        setScrollDir(0) // Down
                    }
                }

                return window.scrollY
            })
        }
    }, 500)

    useEffect(() => {
        const scroll = e => handleNavigation(e)
        
        window.addEventListener('scroll', scroll)
        return window.removeEventListener('scroll', scroll)
    }, [ scrollY ])

    return (
        <header className={ cx(classes.header, { menuOpen, scrollUp: scrollDir, atTop: scrollY <= 0 } )}>
            <span className={classes.background} />
            <Link to="/" onClick={ () => setMenuOpen(false) }>
                <img src="https://cdn-tiltingpoint-website.tiltingpoint.io/static/logo.svg" alt="Tilting Point" className={classes.logo} />
            </Link>
            <Button
                className={classes.button}
                clickHandler={ e => setMenuOpen(!menuOpen) }
            >
                <span key='desktop' className={classes.menuLabel}>
                    { !menuOpen ? 'Menu' : <FontAwesomeIcon icon={[ 'far', 'times' ]} color="black" className="icon" /> }
                </span>

                <span key='mobile' className={cx(classes.menuIcon, {
                    active: menuOpen,
                })}>
                    <span />
                    <span />
                    <span />
                </span>
            </Button>
        </header>
    )
}

export default is(style)(Header)
